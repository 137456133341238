export const appendStyle = (filename) => {
  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.className = 'gx-style';
  link.href = `/css/${filename}.css`;
  // setTimeout(() => {
  //   const innerChildren = document.getElementsByClassName('gx-style');
  //   if (innerChildren.length > 1) {
  //     for (let index = 0; index < innerChildren.length; index += 1) {
  //       if (index + 1 < innerChildren.length) {
  //         const child = innerChildren[index];
  //         child.parentNode.removeChild(child);
  //       }
  //     }
  //   }
  // }, 10000);
  document.body.appendChild(link);
};

export const changeFavicon = (faviconName) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = faviconName;
};
