const I18N_BASE_PATH = 'pages.private.accessKeys.utils.mobileProducts';

const mobileProducts = [
  {
    enabled: true,
    name: 'DocumentDetector',
    title: `${I18N_BASE_PATH}.documentDetector.title`,
    description: `${I18N_BASE_PATH}.documentDetector.description`,
    icon: 'caf-ic_onboardind_big',
  },
  {
    enabled: true,
    name: 'FaceRegistration',
    title: `${I18N_BASE_PATH}.faceRegistration.title`,
    description: `${I18N_BASE_PATH}.faceRegistration.description`,
    icon: 'caf-ic_combateafraude',
  },
  {
    enabled: true,
    name: 'FaceAuthenticator',
    title: `${I18N_BASE_PATH}.faceAuthenticator.title`,
    description: `${I18N_BASE_PATH}.faceAuthenticator.description`,
    icon: 'caf-ic_face',
  },
  {
    enabled: true,
    name: 'PassiveFaceLiveness',
    title: `${I18N_BASE_PATH}.passiveFaceLiveness.title`,
    description: `${I18N_BASE_PATH}.passiveFaceLiveness.description`,
    icon: 'caf-ic_criminal',
  },
  {
    enabled: false,
    name: 'ActiveFaceLiveness',
    title: `${I18N_BASE_PATH}.activeFaceLiveness.title`,
    description: `${I18N_BASE_PATH}.activeFaceLiveness.description`,
    icon: 'caf-ic_criminal',
  },
  {
    enabled: false,
    name: 'DeviceAuthenticator',
    title: `${I18N_BASE_PATH}.deviceAuthenticator.title`,
    description: `${I18N_BASE_PATH}.deviceAuthenticator.description`,
    icon: 'caf-ic_combateafraude',
  },
  {
    enabled: false,
    name: 'AddressCheck',
    title: `${I18N_BASE_PATH}.addressCheck.title`,
    description: `${I18N_BASE_PATH}.addressCheck.description`,
    icon: 'caf-ic_map_pin',
  },
  {
    enabled: false,
    name: 'Security',
    title: `${I18N_BASE_PATH}.security.title`,
    description: `${I18N_BASE_PATH}.security.description`,
    icon: 'caf-ic_admin',
  },
  {
    enabled: true,
    name: 'DocumentOcr',
    title: `${I18N_BASE_PATH}.documentOcr.title`,
    description: `${I18N_BASE_PATH}.documentOcr.description`,
    icon: 'caf-ic_match',
  },
];

export const findMobileProduct = (name) => {
  const filtered = mobileProducts.filter((mp) => mp.name === name);

  return filtered.length ? filtered[0] : {};
};

export default mobileProducts;
