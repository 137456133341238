/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { useTranslation } from 'react-i18next';
import CustomCollapse from '../CustomCollapse';
import PolicyRow from '../PolicyRow';

const I18N_BASE_PATH =
  'pages.private.accessPolicies.components.policiesSelect.actions.components.trustPanels.overviewPanel';

const RULE_NAME = 'metrics';

const MetricsPanel = ({ disabled, formRef }) => {
  const { t: translate } = useTranslation();

  return (
    <CustomCollapse
      ruleName={RULE_NAME}
      disabled={disabled}
      className="no-border-bottom"
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="ic_activity"
    >
      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleMetrics`)}
        icon="ic_activity"
        ruleName={RULE_NAME}
        availability={[true, false, false, false]}
      />
    </CustomCollapse>
  );
};
export default MetricsPanel;
