/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Divider, Avatar } from 'antd';

import { useAuth } from 'hooks/auth';

import { Button } from '@combateafraude/react';

import ProfileDefault from 'assets/images/profile-default.png';
import useUpdateProfileModal from 'components/ModalUpdateProfile';

import './styles.less';

const I18N_BASE_PATH = 'components.userInfo.index';

const UserInfo = () => {
  const { signOut, loggedUser } = useAuth();
  const { t: translate, i18n } = useTranslation();
  const { openModal, UpdateProfileModal } = useUpdateProfileModal();

  const logout = useCallback(() => {
    signOut();
  }, [signOut]);

  const userProfile = useMemo(
    () => (
      <>
        <div className="user-profile" onClick={openModal}>
          <div>
            <Avatar
              src={loggedUser?.profileImageUrl || ProfileDefault}
              size={52}
              className={!loggedUser?.profileImageUrl ? 'grayscale-95' : ''}
            />
          </div>
          <section>
            <h3 className="max-lines-1">{loggedUser?.name}</h3>
            <span className="max-lines-1">{loggedUser?.username}</span>
          </section>
        </div>
        <Divider className="no-mrg-btm mrg-top-8" />
      </>
    ),
    [openModal, loggedUser]
  );
  // const userHasSomeUserInfoPermission = useMemo(() => {
  //   const hasPermission =
  //     hasRequiredPermissions(
  //       loggedUser,
  //       [
  //         'private-faceset:read',
  //         'mobile-faces:read',
  //         'users:read',
  //         'access-keys:read',
  //         'consumption:read',
  //       ],
  //       [],
  //       true
  //     ) || loggedUser?.accountData?.featureFlags?.useOnboardingBuilder;

  //   return hasPermission;
  // }, [loggedUser]);

  // const facesPermission = useMemo(() => {
  //   if (hasRequiredPermissions(loggedUser, ['private-faceset:read'], []))
  //     return '/faces-base/private-faceset';
  //   if (hasRequiredPermissions(loggedUser, ['mobile-faces:read'], []))
  //     return '/faces-base/mobile-faces';

  //   return '/users-permissions/users';
  // }, [loggedUser]);

  const menu = useMemo(
    () => (
      <div>
        {loggedUser && <>{userProfile}</>}

        <div className="gx-flex-column gx-align-items-center gx-justify-content-center">
          <div className="pdd-vertical-20">
            <Button className="btn-logout" onClick={logout}>
              {translate(`${I18N_BASE_PATH}.buttons.logout`)}
            </Button>
          </div>
          <Divider className="no-mrg" />
          <section className="privacy-policies-terms-of-use">
            <div>
              <a
                href={`https://${
                  i18n.language === 'en-US' ? 'en.' : 'www.'
                }combateafraude.com/politicas/politicas-de-privacidade`}
                target="_blank"
                rel="noreferrer"
              >
                {translate(`${I18N_BASE_PATH}.links.privacyPolicy`)}
              </a>
              <a
                href={`https://${
                  i18n.language === 'en-US' ? 'en.' : 'www.'
                }combateafraude.com/politicas/termos-e-condicoes-de-uso`}
                target="_blank"
                rel="noreferrer"
              >
                {translate(`${I18N_BASE_PATH}.links.termsAndConditionsOfUse`)}
              </a>
            </div>
          </section>
        </div>
      </div>
    ),
    [logout, loggedUser, userProfile, translate, i18n]
  );

  return (
    <div id="user-info-component">
      {UpdateProfileModal}
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        overlayClassName="user-dropdown"
      >
        <div className="user-dropdown-trigger">
          <Avatar
            src={loggedUser?.profileImageUrl || ProfileDefault}
            size={28}
            className={!loggedUser?.profileImageUrl ? 'grayscale-95' : ''}
          />
          <i className="caf-ic_arrow_down" />
        </div>
      </Dropdown>
    </div>
  );
};

export default UserInfo;
