import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, message } from 'antd';
import { PageTitle, Button } from '@combateafraude/react';

import { useFetch } from 'services/hooks';
import { cleanApi } from 'services/api';

const I18N_BASE_PATH = 'components.imageCard.deleteFaceModal';

const DeleteFace = ({ _id, refreshList, loggedUser }) => {
  const { delete: deleteFace, loading } = useFetch({ customApi: cleanApi });
  const { t: translate } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const token = useMemo(() => loggedUser?.accountData?.products?.trust?.cafAccessToken, [
    loggedUser,
  ]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onDeleteFace = useCallback(async () => {
    try {
      await deleteFace({
        url: `${process.env.REACT_APP_BASE_URL_CAF_API}/private-faceset/${_id}`,
        config: {
          params: {
            token,
          },
        },
      });
      refreshList();
      closeModal();
      message.success(translate(`${I18N_BASE_PATH}.onDelete.messageSuccess`));
    } catch (err) {
      message.error(translate(`${I18N_BASE_PATH}.onDelete.messageError`));
      closeModal();
    }
  }, [deleteFace, _id, token, refreshList, closeModal, translate]);

  const DeleteFaceModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={600}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle align="center" title={translate(`${I18N_BASE_PATH}.title`)} />
          <div className="flex center mrg-top-25 mrg-btm-35">
            <span className="text-center font-size-16">
              {translate(`${I18N_BASE_PATH}.subtitle`)}
            </span>
          </div>

          <div className="flex center-end mrg-top-40">
            <Button
              type="default"
              onClick={closeModal}
              className="btn-cancel"
              disabled={loading}
            >
              {translate(`${I18N_BASE_PATH}.button.cancel`)}
            </Button>
            <Button
              type="danger"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              onClick={onDeleteFace}
            >
              {loading
                ? translate(`${I18N_BASE_PATH}.loading`)
                : translate(`${I18N_BASE_PATH}.button.delete`)}
            </Button>
          </div>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, translate, loading, onDeleteFace]
  );

  return { openModal, closeModal, DeleteFaceModal };
};

export default DeleteFace;
