import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Modal, Form, Select, message } from 'antd';
import { PageTitle, Button } from '@combateafraude/react';

import { useFetch } from 'services/hooks';

const AddUsersToGroup = ({ refreshListRef }) => {
  const [form] = Form.useForm();
  const { t: translate } = useTranslation();
  const { id } = useParams();

  const { post, get, loading } = useFetch();

  const [modalVisible, setModalVisible] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [users, setUsers] = useState([]);

  const closeModal = useCallback(
    ({ resetFields = true }) => {
      setModalVisible(false);

      setTimeout(() => {
        if (resetFields) form.resetFields();
        setDisabledSubmit(true);
      }, 500);
    },
    [form]
  );

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const getUsers = useCallback(async () => {
    try {
      const response = await get({
        url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users/all`,
      });
      const filterUsers = response?.docs?.filter((user) => user?.usersGroupId !== id);
      setUsers(filterUsers);
    } catch {
      message.error(
        translate(
          'pages.private.groups.components.groupContent.addUsersToGroup.getUsers.messageError'
        )
      );
    }
  }, [get, id, translate]);

  const handleFormChange = useCallback((values) => {
    if (!values?.users?.length) {
      setDisabledSubmit(true);
    } else {
      setDisabledSubmit(false);
    }
  }, []);

  const onAddUsersToGroup = useCallback(
    async (payload) => {
      try {
        await post({
          url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users-groups/${id}/users`,
          payload: { usersIds: payload?.users },
        });
        refreshListRef.current();
        closeModal({ resetFields: true });
        message.success(
          translate(
            'pages.private.groups.components.groupContent.addUsersToGroup.onAddUsersToGroup.messageSuccess'
          )
        );
      } catch {
        message.error(
          translate(
            'pages.private.groups.components.groupContent.addUsersToGroup.onAddUsersToGroup.messageError'
          )
        );
      }
    },
    [post, id, refreshListRef, closeModal, translate]
  );

  const options = useMemo(
    () =>
      users?.map((user) => ({
        value: user?.id,
        label: user?.name,
      })),
    [users]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const AddUsersToGroupModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={550}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            title={translate(
              'pages.private.groups.components.groupContent.addUsersToGroup.pageTitle.title'
            )}
            subtitle={translate(
              'pages.private.groups.components.groupContent.addUsersToGroup.pageTitle.subtitle'
            )}
          />

          <Form
            form={form}
            layout="vertical"
            onValuesChange={handleFormChange}
            validateTrigger="finish"
            className="mrg-top-30"
            onFinish={onAddUsersToGroup}
          >
            <Form.Item
              name="users"
              label={translate(
                'pages.private.groups.components.groupContent.addUsersToGroup.users'
              )}
              rules={[{ required: true }]}
            >
              <Select mode="multiple" optionFilterProp="label" options={options} />
            </Form.Item>

            <div className="flex end-center mrg-top-40">
              <Button
                type="default"
                onClick={closeModal}
                className="btn-cancel"
                disabled={loading}
              >
                {translate(
                  'pages.private.groups.components.groupContent.addUsersToGroup.buttons.cancel'
                )}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={disabledSubmit || loading}
              >
                {loading
                  ? translate(
                      'pages.private.groups.components.groupContent.addUsersToGroup.buttons.loading'
                    )
                  : translate(
                      'pages.private.groups.components.groupContent.addUsersToGroup.buttons.okText'
                    )}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      form,
      handleFormChange,
      onAddUsersToGroup,
      options,
      loading,
      disabledSubmit,
      translate,
    ]
  );

  return { openModal, closeModal, AddUsersToGroupModal };
};

export default AddUsersToGroup;
