/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

export default function StackedProgressBar({ items, total }) {
  const { t: translate } = useTranslation();
  const { i18nNumberToFormattedString } = useI18nFormatters();

  const [barItems, setBarItems] = useState([]);

  const getColor = () => {
    let color = Math.floor(Math.random() * 16777215).toString(16);
    if (color.length !== 6) {
      color += '0'.repeat(6 - color.length);
    }
    return `#${color}`;
  };

  const progressBar = useMemo(() => {
    const progressBarItems = [];

    const progressBarContent = items?.map((doc) => {
      if (!doc.totalPrice) return null;

      const percentage = `${(((doc.totalPrice || 0) / total) * 100).toFixed(2)}%`;

      const color = getColor();

      progressBarItems.push({ name: doc.name, color, percentage });

      return (
        <Tooltip title={`${doc.name} ${percentage}`}>
          <div
            className="progressBarStack"
            style={{
              height: '100%',
              width: percentage,
              backgroundColor: color,
            }}
          />
        </Tooltip>
      );
    });

    setBarItems(progressBarItems);

    return <div className="progressBarContent">{progressBarContent}</div>;
  }, [items, total]);

  const progressBarInfo = useMemo(() => {
    const info = barItems?.map((item) => (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '5px',
          fontSize: '14px',
          lineHeight: '2',
        }}
      >
        <div
          style={{
            display: 'block',
            height: '12px',
            width: '12px',
            borderRadius: '50%',
            backgroundColor: `${item.color}`,
            marginRight: '6px',
          }}
        />
        <span style={{ marginRight: 10 }}>{item.name}</span>
      </span>
    ));

    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: '10px',
          flexWrap: 'wrap',
        }}
      >
        {info}
      </div>
    );
  }, [barItems]);

  const progressBarHeader = useMemo(
    () => (
      <div className="progressBarHeader">
        <p className="font-size-16">
          {translate(
            'pages.private.consumptions.components.stackedProgressBar.monthlyConsumption'
          )}
        </p>
        <p className="font-size-16">
          {translate('pages.private.consumptions.components.stackedProgressBar.total')}{' '}
          {i18nNumberToFormattedString(total || 0, 'pt-BR')}
        </p>
      </div>
    ),
    [total, translate, i18nNumberToFormattedString]
  );

  return (
    <>
      {progressBarHeader}
      {progressBar}
      {progressBarInfo}
    </>
  );
}
