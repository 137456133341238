export const i18nDateFormats = {
  default: {
    'pt-BR': "dd/MM/yyyy 'às' HH:mm",
    'en-US': "MM/dd/yyyy 'at' HH:mm",
  },
  1: {
    'pt-BR': 'dd/MM/yyyy',
    'en-US': 'MM/dd/yyyy',
  },
  2: {
    'pt-BR': 'dd/MM/yy',
    'en-US': 'MM/dd/yy',
  },
  3: {
    'pt-BR': "dd/MM/yyyy 'às' HH:mm:ss",
    'en-US': "MM/dd/yyyy 'at' HH:mm:ss",
  },
  4: {
    'pt-BR': "dd/MM/yyyy 'às' HH:mm",
    'en-US': "MM/dd/yyyy 'at' HH:mm",
  },
  5: {
    'pt-BR': "dd 'de' MMMM",
    'en-US': "MMM 'of' ddd",
  },
  6: {
    'pt-BR': "dd 'de' MMMM 'de' yyyy",
    'en-US': "MMM 'of' dd 'of' yyyy",
  },
  7: {
    'pt-BR': "dd/MM/yy 'às' HH:mm:ss",
    'en-US': "MM/dd/yy 'at' HH:mm:ss",
  },
  8: {
    'pt-BR': 'dd/MM/yy',
    'en-US': 'MM/dd/yy',
  },
};

export const i18nCurrencyFormats = {
  'pt-BR': 'R$',
  'en-US': 'U$',
};
