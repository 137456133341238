import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'services/hooks';
import { Menu, Dropdown, Spin, Card, Row, Typography, Col, message } from 'antd';

import docIcon from 'assets/images/ib_doc.svg';
import excelIcon from 'assets/images/ib_excel.svg';
import pdfIcon from 'assets/images/ib_pdf.svg';
import zipIcon from 'assets/images/ib_zip.svg';
import pngIcon from 'assets/images/ib_png.svg';
import notify from 'assets/images/notify.svg';

const { Text } = Typography;
const icon = {
  doc: docIcon,
  docx: docIcon,
  odt: docIcon,
  ods: excelIcon,
  xls: excelIcon,
  xlsx: excelIcon,
  png: pngIcon,
  jpeg: pngIcon,
  jpg: pngIcon,
  pdf: pdfIcon,
  rar: zipIcon,
  zip: zipIcon,
  '7z': zipIcon,
};

const I18N_BASE_PATH = 'pages.private.registrationInfo.page';

const RegistrationFiles = () => {
  const { t: translate } = useTranslation();
  const [loading, setLoading] = useState(true);

  const { get } = useFetch();
  const [data, setData] = useState({});

  const getFilesData = useCallback(async () => {
    try {
      const response = await get({
        url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/registration-info/files`,
      });

      setData(response);
      setLoading(false);
    } catch (err) {
      const errMessage = err?.response?.data?.message;
      message.error(errMessage);
    }
  }, [get]);

  useEffect(() => getFilesData(), [getFilesData]);

  const handleDownloadDocument = useCallback(
    async (_id) => {
      const resp = await get({
        url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/registration-info/files/${_id}`,
      });

      window.open(resp.data.fileName, '_blank');
    },
    [get]
  );

  const documents = useMemo(() => data?.data, [data]);

  const hasData = useMemo(() => {
    if (documents?.totalItems === 0) return false;
    return true;
  }, [documents]);

  const renderDropdownItems = useCallback(
    (_id) => (
      <Menu>
        <Menu.Item key="0" onClick={() => handleDownloadDocument(_id)}>
          <i className="caf-ic_c_download" />
          {translate(`${I18N_BASE_PATH}.registrationFiles.menu.itemDownload`)}
        </Menu.Item>
      </Menu>
    ),
    [handleDownloadDocument, translate]
  );

  return (
    <div id="files-download-card-component">
      <Card>
        {loading ? (
          <Spin className="spin-position" />
        ) : (
          <>
            {hasData ? (
              <div className="mrg-top-10">
                {documents?.docs?.map((file) => (
                  <>
                    <Row span={8} className="documents-list-item no-mrg-horizon">
                      <Col className="documents-list-item-content">
                        <img
                          className="mrg-right-15"
                          src={icon[file.fileName.split('.').pop()]}
                          alt="Ícone"
                          height="36px"
                        />
                        <Text className="text-style">{file.fileName}</Text>
                        <Dropdown
                          overlay={() => renderDropdownItems(file._id)}
                          trigger={['click']}
                          placement="bottomRight"
                        >
                          <div className="btn-more-icon">
                            <i className="caf-ic_more-vertical" />
                          </div>
                        </Dropdown>
                      </Col>
                    </Row>
                  </>
                ))}
              </div>
            ) : (
              <div className="mrg-top-10">
                <Row span={8} className="no-mrg-horizon">
                  <Col className="documents-list-item-content">
                    <img className="mrg-right-15" src={notify} alt="" />
                    <Text className="style-text">
                      {translate(`${I18N_BASE_PATH}.registrationFiles.notFound`)}
                    </Text>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default RegistrationFiles;
