import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';

import { hasRequiredPermissions } from 'routes/permissionFunctions';
import PermissionWrapper from 'routes/PermissionWrapper';
import { useAuth } from 'hooks/auth';

import useRemovePolicy from './RemovePolicy';

const PoliciesActionsList = ({ policy, refreshListRef }) => {
  const { t: translate } = useTranslation();
  const { openModal, RemovePolicyModal } = useRemovePolicy({ policy, refreshListRef });
  const { loggedUser } = useAuth();

  return (
    <>
      {RemovePolicyModal}
      <PermissionWrapper
        or
        requiredPermissions={['permissions:update', 'permissions:delete']}
      >
        <Dropdown
          overlay={
            <Menu className="pdd-vertical-5">
              {hasRequiredPermissions(loggedUser, ['permissions:update'], []) && (
                <Menu.Item key="editPolicy" className="text-dark">
                  <Link to={`/users-permissions/access-policies/${policy?.id}`}>
                    <i className="caf-ic_edit mrg-right-10" />
                    <span className="text-semibold">
                      {translate(
                        'pages.private.users.components.policiesActions.policiesActionsList.editPolicy'
                      )}
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {hasRequiredPermissions(
                loggedUser,
                ['permissions:delete', 'permissions:update'],
                []
              ) && <Menu.Divider className="mrg-horizon-15 mrg-vertical-5" />}

              {hasRequiredPermissions(loggedUser, ['permissions:delete'], []) && (
                <Menu.Item key="deletePolicy" className="text-danger" onClick={openModal}>
                  <i className="caf-ic_trash mrg-right-10" />
                  <span className="text-semibold">
                    {translate(
                      'pages.private.users.components.policiesActions.policiesActionsList.deletePolicy'
                    )}
                  </span>
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon btn-more-icon-hover">
            <i className="caf-ic_more-vertical" />
          </div>
        </Dropdown>
      </PermissionWrapper>
    </>
  );
};

PoliciesActionsList.propTypes = {
  policy: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    product: PropTypes.string,
  }),
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

PoliciesActionsList.defaultProps = {
  policy: {},
};

export default PoliciesActionsList;
