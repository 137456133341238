/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Divider, Dropdown, Menu } from 'antd';

import { useAuth } from 'hooks/auth';
import { hasRequiredPermissions } from 'routes/permissionFunctions';

import PermissionWrapper from 'routes/PermissionWrapper';
import CustomerCustomizationWrapper from '../CustomerCustomizationWrapper';

import './styles.less';

const I18N_BASE_PATH = 'components.managementMenu';

const ManagementMenu = () => {
  const { t: translate } = useTranslation();
  const isCafIo = window.location.origin.indexOf('caf.io') !== -1;

  const { loggedUser } = useAuth();

  const facesPermission = useMemo(() => {
    if (hasRequiredPermissions(loggedUser, ['private-faceset:read'], []))
      return '/faces-base/private-faceset';
    if (hasRequiredPermissions(loggedUser, ['mobile-faces:read'], []))
      return '/faces-base/mobile-faces';

    return '/users-permissions/users';
  }, [loggedUser]);

  const linkTrust = useMemo(() => {
    if (process.env.REACT_APP_ENV !== 'prod') {
      return isCafIo
        ? `https://${process.env.REACT_APP_ENV}.trust.caf.io/dashboard/`
        : `https://${process.env.REACT_APP_ENV}.trust.combateafraude.com/dashboard/`;
    }

    return isCafIo
      ? 'https://trust.caf.io/dashboard/'
      : 'https://trust.combateafraude.com/dashboard/';
  }, [isCafIo]);

  const menu = useMemo(
    () => (
      <div>
        {loggedUser && (
          <>
            <Menu>
              <Menu.Item className="nav-link-management-container mrg-btm-5">
                <div className="nav-link-management">
                  <i className="caf-ic_duplicate" />
                  <a href={linkTrust}>
                    <span>Trust</span>
                  </a>
                </div>
              </Menu.Item>
            </Menu>
            <PermissionWrapper
              requiredPermissions={['users:read', 'permissions:read']}
              or
              product="management"
            >
              <Menu>
                <Menu.Item className="nav-link-management-container">
                  <NavLink to="/users" className="nav-link-management">
                    <i className="caf-ic_users" />
                    <span>{translate(`${I18N_BASE_PATH}.menu.usersAndPermissions`)}</span>
                  </NavLink>
                </Menu.Item>
              </Menu>
            </PermissionWrapper>
            <PermissionWrapper requiredPermissions={['consumption:read']}>
              <Menu>
                <Menu.Item className="nav-link-management-container">
                  <NavLink to="/consumption" className="nav-link-management">
                    <i className="caf-ic_dollar_sign" />
                    <span>{translate(`${I18N_BASE_PATH}.menu.consumption`)}</span>
                  </NavLink>
                </Menu.Item>
              </Menu>
            </PermissionWrapper>

            <Menu>
              <Menu.Item className="nav-link-management-container">
                <a
                  href={`${
                    isCafIo
                      ? process.env.REACT_APP_BASE_URL_TRUST_WEB_NEW
                      : process.env.REACT_APP_BASE_URL_TRUST_WEB
                  }/tags-and-reasons`}
                  className="nav-link-management"
                >
                  <i className="caf-ic_tag" />
                  <span>{translate(`${I18N_BASE_PATH}.menu.tagsAndReasons`)}</span>
                </a>
              </Menu.Item>
            </Menu>
            <PermissionWrapper
              requiredPermissions={['private-faceset:read', 'mobile-faces:read']}
              or
              product="management"
            >
              <Menu>
                <Menu.Item className="nav-link-management-container">
                  <NavLink to={facesPermission} className="nav-link-management">
                    <i className="caf-ic_face" />
                    <span>{translate(`${I18N_BASE_PATH}.menu.facesBase`)}</span>
                  </NavLink>
                </Menu.Item>
              </Menu>
            </PermissionWrapper>
            <PermissionWrapper requiredPermissions={['access-keys:read']}>
              <Menu>
                <Menu.Item className="nav-link-management-container">
                  <NavLink to="/access-keys" className="nav-link-management">
                    <i className="caf-ic_key" />
                    <span>{translate(`${I18N_BASE_PATH}.menu.accessKeys`)}</span>
                  </NavLink>
                </Menu.Item>
              </Menu>
            </PermissionWrapper>
            {loggedUser?.accountData?.featureFlags?.useOnboardingBuilder && (
              <PermissionWrapper requiredPermissions={['users:read']}>
                <Menu>
                  <Menu.Item className="nav-link-management-container">
                    <NavLink to="/onboarding-builder" className="nav-link-management">
                      <i className="caf-ic_image" />
                      <span>{translate(`${I18N_BASE_PATH}.menu.onboardingBuilder`)}</span>
                    </NavLink>
                  </Menu.Item>
                </Menu>
              </PermissionWrapper>
            )}
            <PermissionWrapper
              requiredPermissions={['profile-templates:read,onboardings:read']}
            >
              <Menu>
                <Menu.Item className="nav-link-management-container">
                  <a
                    href={`${
                      isCafIo
                        ? process.env.REACT_APP_BASE_URL_TRUST_WEB_NEW
                        : process.env.REACT_APP_BASE_URL_TRUST_WEB
                    }/activities`}
                    className="nav-link-management"
                  >
                    <i className="caf-ic_processing_line" />
                    <span>{translate(`${I18N_BASE_PATH}.menu.activities`)}</span>
                  </a>
                </Menu.Item>
              </Menu>
            </PermissionWrapper>
            <Divider orientation="left">
              {translate(`${I18N_BASE_PATH}.dividers.settings`)}
            </Divider>

            <PermissionWrapper requiredPermissions={['profile-templates:read']}>
              <Menu>
                <Menu.Item className="nav-link-management-container">
                  <a
                    href={`${
                      isCafIo
                        ? process.env.REACT_APP_BASE_URL_TRUST_WEB_NEW
                        : process.env.REACT_APP_BASE_URL_TRUST_WEB
                    }/profile-templates/profile-models?active=true`}
                    className="nav-link-management"
                  >
                    <i className="caf-ic_check-square" />
                    <span>{translate(`${I18N_BASE_PATH}.menu.profileModels`)}</span>
                  </a>
                </Menu.Item>
              </Menu>
            </PermissionWrapper>

            {loggedUser?.accountData?.featureFlags?.profilesModule && (
              <Menu>
                <Menu.Item className="nav-link-management-container">
                  <PermissionWrapper requiredPermissions={['routines:read']}>
                    <a
                      href={`${
                        isCafIo
                          ? process.env.REACT_APP_BASE_URL_TRUST_WEB_NEW
                          : process.env.REACT_APP_BASE_URL_TRUST_WEB
                      }/routines`}
                      className="nav-link-management"
                    >
                      <i className="caf-ic_calendar" />
                      <span>{translate(`${I18N_BASE_PATH}.menu.routines`)}</span>
                    </a>
                  </PermissionWrapper>
                </Menu.Item>
              </Menu>
            )}
            <Divider orientation="left">
              {translate(`${I18N_BASE_PATH}.dividers.security`)}
            </Divider>

            <PermissionWrapper requiredPermissions={['restrictive-list:read']}>
              <Menu>
                <Menu.Item className="nav-link-management-container">
                  <a
                    href={`${
                      isCafIo
                        ? process.env.REACT_APP_BASE_URL_TRUST_WEB_NEW
                        : process.env.REACT_APP_BASE_URL_TRUST_WEB
                    }/restrictions`}
                    className="nav-link-management"
                  >
                    <i className="caf-ic_x_close" />
                    <span>{translate(`${I18N_BASE_PATH}.menu.restrictions`)}</span>
                  </a>
                </Menu.Item>
              </Menu>
            </PermissionWrapper>
            <CustomerCustomizationWrapper customer="SKY">
              <Menu>
                <Menu.Item className="nav-link-management-container">
                  <a
                    href={`${
                      isCafIo
                        ? process.env.REACT_APP_BASE_URL_TRUST_WEB_NEW
                        : process.env.REACT_APP_BASE_URL_TRUST_WEB
                    }/blocked-documents`}
                    className="nav-link-management"
                  >
                    <i className="caf-lock" />
                    <span>{translate(`${I18N_BASE_PATH}.menu.blockedDocuments`)}</span>
                  </a>
                </Menu.Item>
              </Menu>
            </CustomerCustomizationWrapper>
          </>
        )}
      </div>
    ),
    [facesPermission, isCafIo, linkTrust, loggedUser, translate]
  );

  return (
    <div id="management-info-component">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        overlayClassName="management-dropdown"
      >
        <div className="management-dropdown-trigger">
          <i className="caf-ic_settings icon-color" />
        </div>
      </Dropdown>
    </div>
  );
};

export default ManagementMenu;
