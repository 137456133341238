import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import Cookies from 'js-cookie';

const isLocalhost = !!window.location.origin.startsWith('http://localhost');

const LANGUAGE = isLocalhost ? 'CAF_language' : '__Secure-CAF_language';

const languageSelected =
  Cookies.get(LANGUAGE) !== undefined && Cookies.get(LANGUAGE) !== 'undefined'
    ? Cookies.get(LANGUAGE)
    : window.navigator.language;

const i18nConfig = {
  fallbackLng: 'pt-BR',
  lng: process.env.REACT_APP_ENV !== 'prod' ? languageSelected : 'pt-BR',
  whitelist: ['pt-BR', 'en-US'],
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(Backend).use(initReactI18next).init(i18nConfig);

export default i18n;
