const validateMessages = {
  'pt-BR': {
    required: 'Obrigatório',
  },
  'en-US': {
    required: 'Required',
  },
};

export default validateMessages;
