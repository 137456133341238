import { useAuth } from 'hooks/auth';

import api from 'services/api';

let lastApiErrorUrl;

const HttpInterceptor = () => {
  const { refreshToken, signOut } = useAuth();

  api.interceptors.response.use(
    (response) => {
      if (response?.config?.url !== `${process.env.REACT_APP_BASE_URL_AUTH_API}/token`) {
        lastApiErrorUrl = undefined;
      }
      return response;
    },
    async (error) => {
      const urlsToCompare = [
        `${process.env.REACT_APP_BASE_URL_AUTH_API}/token`,
        lastApiErrorUrl,
      ];

      if (urlsToCompare.includes(error?.response?.config?.url)) {
        return Promise.reject(error);
      }

      if (error.response && error.response.status === 401) {
        lastApiErrorUrl = error?.response?.config?.url;
        return refreshToken().then((res) => {
          const { access_token } = res || {};
          error.config.headers.Authorization = `Bearer ${access_token}`;
          return api.request(error.config).catch((err) => {
            if (err?.response?.status === 401) {
              signOut();
            }
          });
        });
      }
      return Promise.reject(error);
    }
  );

  return null;
};

export default HttpInterceptor;
