import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, InputNumber, Upload, Tooltip } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import _ from 'lodash';

import { useAuth } from 'hooks/auth';

import { cleanApi } from 'services/api';
import { useFetch } from 'services/hooks';
import { useComponentList } from 'components/List';
import { Button, PageTitle } from '@combateafraude/react';
import { hasRequiredPermissions } from 'routes/permissionFunctions';
import SearchInput from 'components/Form/SearchInput';

import Card from './components/Card';
import useModalNewFace from './components/ModalNewFace';

import './styles.less';

const { Dragger } = Upload;

const I18N_BASE_PATH = 'pages.private.privateFaceset';

const PrivateFaceset = () => {
  const { t: translate } = useTranslation();
  const [form] = Form.useForm();
  const [imgPreview, setImgPreview] = useState();
  const { loggedUser } = useAuth();

  const token = useMemo(() => loggedUser?.accountData?.products?.trust?.cafAccessToken, [
    loggedUser,
  ]);

  const hasCreatePermission = useMemo(
    () => hasRequiredPermissions(loggedUser, ['private-faceset:create'], []),
    [loggedUser]
  );

  const [query] = useQueryParams({
    cpf: StringParam,
  });

  const { data, loading, post, clearData } = useFetch({ customApi: cleanApi });

  const { componentList, setFilterData, refreshList } = useComponentList({
    component: Card,
    customApi: cleanApi,
    rowKey: '_id',
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_CAF_API}/private-faceset`,
      config: {
        params: {
          _galleryFormat: true,
          token,
        },
      },
    },
    queryParams: {
      cpf: StringParam,
    },
  });
  const refreshListRef = useRef(() => {});

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  const handleFormChange = _.debounce((__, values) => {
    setFilterData({
      ...values,
      cpf: values.cpf ? values.cpf.trim() : undefined,
    });
  }, 500);

  const { openModal, modalContent } = useModalNewFace({ refreshListRef });

  const handleBeforeUpload = useCallback(
    (file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64 = e?.target?.result;
        setImgPreview(String(base64));
        const parsedBase64Array = String(base64).split(',');
        parsedBase64Array.shift();
        form.setFieldsValue({ image: parsedBase64Array.join('') });
      };

      reader.readAsDataURL(file);

      return false;
    },
    [form]
  );

  const handleFormSubmit = useCallback(
    async (formData) => {
      post({
        url: `${process.env.REACT_APP_BASE_URL_CAF_API}/private-faceset/search`,
        payload: { image: formData.image },
        config: {
          params: {
            threshold: formData.similarityLevel,
            token,
          },
        },
      });
    },
    [post, token]
  );

  const handleClearData = useCallback(async () => {
    clearData();
    form.setFieldsValue({});
    setImgPreview(undefined);
  }, [clearData, form]);

  return (
    <div id="private-faceset">
      {modalContent}
      <Row align="middle" className="flex no-mrg">
        <Col className="flex-1 page-title">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.pageTitle.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.pageTitle.subtitle`)}
          />
        </Col>
        <Col className="flex end flex-1">
          <Form
            layout="inline"
            onValuesChange={handleFormChange}
            initialValues={{ cpf: query?.cpf }}
          >
            <SearchInput
              name="_filter"
              placeholder={translate(
                `${I18N_BASE_PATH}.pageTitle.form.search.placeholder`
              )}
              style={{ minWidth: 350 }}
            />
          </Form>
          {hasCreatePermission ? (
            <Button type="primary" htmlType="button" onClick={openModal}>
              <i className="caf-ic_add pdd-right-5" />
              {translate(`${I18N_BASE_PATH}.pageTitle.form.newFaceButton`)}
            </Button>
          ) : (
            <Tooltip
              placement="top"
              title="Você não tem permissão para adicionar novas faces"
            >
              <Button
                type="primary"
                htmlType="button"
                onClick={openModal}
                disabled={hasCreatePermission}
              >
                <i className="caf-ic_add pdd-right-5" />
                {translate(`${I18N_BASE_PATH}.pageTitle.form.newFaceButton`)}
              </Button>
            </Tooltip>
          )}
        </Col>
      </Row>

      <Row className="flex no-mrg">
        <Col span={5} className="no-pdd">
          <strong>{translate(`${I18N_BASE_PATH}.pageTitle.form.image.title`)}</strong>

          <Form
            form={form}
            layout="vertical"
            className="no-pdd mrg-top-10 mrg-left-5"
            initialValues={{ similarityLevel: 85 }}
            onFinish={handleFormSubmit}
          >
            <Form.Item name="image" rules={[{ required: true }]}>
              <Dragger
                maxCount={1}
                beforeUpload={handleBeforeUpload}
                accept="image/*"
                showUploadList={false}
              >
                <div className="pdd-vertical-30 flex center flex-column">
                  {imgPreview ? (
                    <img src={imgPreview} alt="Foto do suspeito" />
                  ) : (
                    <>
                      <div className="icon-container">
                        <i className="caf-ic_plus font-size-20" />
                      </div>
                      <p className="mrg-top-10">
                        {translate(`${I18N_BASE_PATH}.pageTitle.form.image.addImage`)}
                      </p>
                    </>
                  )}
                </div>
              </Dragger>
            </Form.Item>

            <Form.Item
              name="similarityLevel"
              label={translate(
                `${I18N_BASE_PATH}.pageTitle.form.image.similarityThreshold`
              )}
            >
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace('%', '')}
              />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              className="width-100"
              loading={loading}
              disabled={loading}
            >
              {translate(`${I18N_BASE_PATH}.pageTitle.form.image.searchButton`)}
            </Button>

            <Button
              type="link"
              htmlType="button"
              className="width-100"
              disabled={loading}
              onClick={handleClearData}
            >
              {translate(`${I18N_BASE_PATH}.pageTitle.form.image.clearSearch`)}
            </Button>
          </Form>
        </Col>
        {data?.faceMatches ? (
          <Col span={19}>
            <div className="mrg-btm-20 text-bold">
              {translate(`${I18N_BASE_PATH}.images.foundSimilarImages`)}
            </div>
            {data?.faceMatches.map((fm) => (
              <Card data={fm} refreshList={refreshListRef} />
            ))}
          </Col>
        ) : (
          <Col span={19}>{componentList}</Col>
        )}
      </Row>
    </div>
  );
};

export default PrivateFaceset;
