import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import api from 'services/api';

import { secondsToDay } from 'utils/formatters';

const isLocalhost = !!window.location.origin.startsWith('http://localhost');

const ACCESS_TOKEN = '__Secure-CAF_access_token';
const REFRESH_TOKEN = '__Secure-CAF_refresh_token';
const LANGUAGE = isLocalhost ? 'CAF_language' : '__Secure-CAF_language';

const COOKIE_OPTIONS = {
  domain: '.combateafraude.com',
  secure: true,
  sameSite: 'strict',
};

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [loggedUser, setLoggedUser] = useState();
  const [language, setLanguage] = useState();
  const [loadingAuth, setLoadingAuth] = useState(true);
  const { i18n } = useTranslation();

  const handleChangeLanguage = useCallback(
    (lang) => {
      i18n.changeLanguage(lang);

      Cookies.set(LANGUAGE, lang, COOKIE_OPTIONS);
    },
    [i18n]
  );

  useEffect(() => {
    if (language && language !== i18n.language) {
      handleChangeLanguage(language);
    }
  }, [handleChangeLanguage, i18n, language]);

  const refreshToken = useCallback(async () => {
    const refresh_token = Cookies.get(REFRESH_TOKEN);

    return api
      .post(`${process.env.REACT_APP_BASE_URL_AUTH_API}/token`, {
        grant_type: 'refresh_token',
        refresh_token,
      })
      .then((response) => {
        const { expires_in, access_token } = response?.data || {};

        const ACCESS_TOKEN_OPTIONS = {
          ...COOKIE_OPTIONS,
          expires: secondsToDay(expires_in),
        };

        Cookies.set(ACCESS_TOKEN, access_token, ACCESS_TOKEN_OPTIONS);

        api.defaults.headers.Authorization = `Bearer ${access_token}`;

        return response?.data;
      });
  }, []);

  const getLoggedUser = useCallback(
    async () =>
      api
        .get(`${process.env.REACT_APP_BASE_URL_AUTH_API}/users/me`)
        .then(({ data: response }) => {
          const userLanguage = response?.data?.language || Cookies.get(LANGUAGE);

          setLoggedUser(response?.data);
          setLanguage(userLanguage);

          return response?.data;
        })
        .finally(() => setLoadingAuth(false)),
    []
  );

  const signOut = useCallback(() => {
    Cookies.remove(ACCESS_TOKEN, COOKIE_OPTIONS);
    Cookies.remove(REFRESH_TOKEN, COOKIE_OPTIONS);

    setLoggedUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        refreshToken,
        getLoggedUser,
        signOut,
        loggedUser,
        loadingAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
