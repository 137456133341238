import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@combateafraude/react';

import './styles.less';

const ViewMore = ({ onClick, title, type, icon, text, loading, ...rest }) => (
  <div id="view-more-component" {...rest}>
    <Button type={type} onClick={onClick} loading={loading} rounded={false}>
      {!text && <i className={icon} />}
      {title}
    </Button>
  </div>
);

ViewMore.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.bool,
  loading: PropTypes.bool,
};

ViewMore.defaultProps = {
  title: 'Ver mais',
  type: 'default',
  icon: 'caf-ic_plus',
  text: false,
  loading: false,
};

export default ViewMore;
