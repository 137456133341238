/* eslint-disable react/prop-types */
import React from 'react';

import { useTranslation } from 'react-i18next';
import CustomCollapse from '../CustomCollapse';

import PolicyRow from '../PolicyRow';

const I18N_BASE_PATH =
  'pages.private.accessPolicies.components.policiesSelect.actions.components.managementPanels.userPanels';

const UsersPanel = ({ disabled, formRef }) => {
  const { t: translate } = useTranslation();
  return (
    <CustomCollapse
      ruleName="users"
      disabled={disabled}
      className="no-border-bottom"
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="ic_person"
    >
      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleUsers`)}
        icon="ic_person"
        ruleName="users"
        availability={[true, true, true, true]}
      />

      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleUsersPDV`)}
        icon="ic_person"
        ruleName="users-pdv"
        availability={[true, true, true, true]}
        allowedTenants={[
          '1083c7ad-4b04-4d3b-b344-65d9bd3336c4',
          'fb3d6bd4-7892-436a-b9b1-9f284389995e',
          'e8a13640-40be-4ac3-91a4-bbbc488dff61',
        ]}
      />

      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titlePermissions`)}
        icon="ic_users"
        ruleName="permissions"
        availability={[true, true, true, true]}
      />
    </CustomCollapse>
  );
};

export default UsersPanel;
