import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { useGroup } from 'hooks/group';

import UsersInternalList from './components/GroupContent/UsersInternalList';
import Subheader from './components/GroupSubheader';
import Content from './components/GroupContent';

import Wrapper from '../wrapper';

const Groups = () => {
  const { id } = useParams();
  const { loading, group, loadGroup } = useGroup();
  useEffect(() => {
    loadGroup({ id });
  }, [id, loadGroup]);

  return (
    <Wrapper subheader={<Subheader />} hideMainHeader>
      {group && !loading ? (
        <>
          <Content />
          <UsersInternalList groupId={id} />
        </>
      ) : (
        <Spin className="flex center mrg-vertical-50 mrg-horizon-auto" />
      )}
    </Wrapper>
  );
};

export default Groups;
