import React, { useCallback, useRef, useEffect, useMemo, useState } from 'react';
import { message, Dropdown, Form, Menu, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { useQueryParams, StringParam, withDefault } from 'use-query-params';
import { Button, PageTitle } from '@combateafraude/react';
import _ from 'lodash';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import Wrapper from 'pages/private/wrapper';

import SearchInput from 'components/Form/SearchInput';
import useTable from 'components/List/hooks/useTable';
import PermissionWrapper from 'routes/PermissionWrapper';

import OutsideClickHandler from 'react-outside-click-handler';
import { useFetch } from 'services/hooks';
import useChangeUserStatus from './components/ChangeUserStatus';
import ActionsList from '../UsersPDVActions/ActionsList';
import useCreateUsers from '../UsersActions/CreateUsers';
import SwitchRenderer from '../UsersList/components/SwitchRenderer';

import './styles.less';

const { Option } = Select;

const I18N = `pages.private.users.components.usersListPDV.index`;

const UsersListPDV = () => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const { get } = useFetch();

  const refreshListRef = useRef(() => {});
  const [query] = useQueryParams({
    search: StringParam,
    _order: StringParam,
    _sort: StringParam,
  });

  const [isDropdownExportOpened, setIsDropdownExportOpened] = useState(false);

  const { openModal, CreateUsersModal } = useCreateUsers({ refreshListRef, type: 'pdv' });
  const { openModal: openChangeStatusModal, ChangeUserStatusModal } = useChangeUserStatus(
    {
      refreshListRef,
    }
  );

  const columns = [
    {
      key: 'name',
      title: (
        <span className="column-title">
          {translate('pages.private.users.components.usersListPDV.index.columns.name')}
        </span>
      ),
      dataIndex: 'name',
      sorter: {
        compare: () => 0,
        multiple: 1,
      },
      render: (__, record) => (
        <span className="flex align-center text-dark">{record?.name}</span>
      ),
    },
    {
      key: 'email',
      title: (
        <span className="column-title">
          {translate('pages.private.users.components.usersListPDV.index.columns.email')}
        </span>
      ),
      dataIndex: 'email',
      sorter: {
        compare: () => 0,
        multiple: 2,
      },
    },
    {
      key: 'cnpj',
      title: (
        <span className="column-title">
          {translate('pages.private.users.components.usersListPDV.index.columns.cnpj')}
        </span>
      ),
      dataIndex: 'cnpj',
    },
    {
      key: 'updatedAt',
      title: (
        <span className="column-title">
          {translate(
            'pages.private.users.components.usersListPDV.index.columns.updatedAt'
          )}
        </span>
      ),
      dataIndex: 'updatedAt',
      sorter: {
        compare: () => 0,
        multiple: 3,
      },
      render: (__, record) => i18nFormatDate(record?.updatedAt || record?.createdAt, 6),
    },
    {
      key: 'deletedAt',
      title: <span className="column-title">{translate(`${I18N}.columns.status`)}</span>,
      render: (__, record) => (
        <SwitchRenderer data={record} openModal={openChangeStatusModal} />
      ),
    },
    {
      key: 'actions',
      render: (__, record) => (
        <ActionsList user={record} refreshListRef={refreshListRef} />
      ),
      width: 60,
    },
  ];

  const { tableContent, refreshList, setFilterData } = useTable({
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/caf/users`,
      config: { params: {} },
    },
    queryParams: {
      search: StringParam,
      active: withDefault(StringParam, 'true'),
    },
    columns,
    rowKey: 'id',
  });

  const onExportList = useCallback(async () => {
    try {
      const res = await get({
        url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/caf/users`,
        config: {
          params: { _export: true, ...query },
        },
      });
      window.open(res.url, '_blank');
    } catch (err) {
      message.error(translate('global.hooks.group.loadGroup.error'));
    }
  }, [get, query, translate]);

  const menuExport = useMemo(
    () => (
      <>
        <Menu className="menu-export-list">
          <Menu.Item key="csv_profiles">
            <button
              type="button"
              className="menu-export-list-btn reset-btn"
              onClick={() => onExportList()}
            >
              <i className="caf-ic_c_download" />
              <span>{translate(`${I18N}.menuExport.title`)}</span>
            </button>
          </Menu.Item>
        </Menu>
      </>
    ),
    [onExportList, translate]
  );

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        active: values.active && values.active !== 'all' ? values.active : undefined,
        search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );
  return (
    <Wrapper id="users-list-pdv-component">
      {ChangeUserStatusModal}
      {CreateUsersModal}

      <div className="flex align-center gx-w-100 space-between">
        <PageTitle
          title={translate(
            'pages.private.users.components.usersListPDV.index.pageTitle.title'
          )}
          subtitle={translate(
            'pages.private.users.components.usersListPDV.index.pageTitle.subtitle'
          )}
        />
        <div className="flex row mrg-top-15 mrg-btm-30 align-center">
          <Form
            layout="horizontal"
            className="flex align-center"
            onValuesChange={handleFormChange}
            initialValues={{ _search: query?.search, active: query?.active || 'true' }}
          >
            <Form.Item name="active" className="no-mrg-btm">
              <Select
                bordered={false}
                style={{ width: 100, fontWeight: 500, marginRight: 20 }}
                className="text-dark"
              >
                <Option value="all">{translate(`${I18N}.activeInput.all`)}</Option>
                <Option value="true">{translate(`${I18N}.activeInput.active`)}</Option>
                <Option value="false">{translate(`${I18N}.activeInput.inactive`)}</Option>
              </Select>
            </Form.Item>
            <SearchInput placeholder={translate(`${I18N}.searchInput`)} />
          </Form>
          <PermissionWrapper requiredPermissions={['users-pdv:create']}>
            <div className="mrg-left-15">
              <Button type="primary" className="flex center" onClick={openModal}>
                <i className="caf-ic_plus font-size-15 mrg-right-5" />
                <span>{translate(`${I18N}.newUserPDV`)}</span>
              </Button>
            </div>
          </PermissionWrapper>
          <OutsideClickHandler
            onOutsideClick={() => setIsDropdownExportOpened(false)}
            disabled={!isDropdownExportOpened}
          >
            <Dropdown
              overlay={menuExport}
              trigger={['click']}
              visible={isDropdownExportOpened}
              placement="bottomRight"
            >
              <Button
                type="default"
                shape="circle"
                className="export-list-btn"
                onClick={() => setIsDropdownExportOpened((oldState) => !oldState)}
              >
                <i className="caf-ic_c_download" />
              </Button>
            </Dropdown>
          </OutsideClickHandler>
        </div>
      </div>
      <div className="gx-w-100 mrg-top-10">{tableContent}</div>
    </Wrapper>
  );
};

export default UsersListPDV;
