const renderProductLink = (product, envSide = 'left', domain) => {
  const currentEnv = process.env.REACT_APP_ENV;
  let productLink;

  if (currentEnv !== 'prod') {
    if (envSide === 'left') {
      productLink = `${currentEnv}.${product}`;
    } else {
      productLink = `${product}.${currentEnv}`;
    }
  } else {
    if (!domain || domain === 'caf') {
      return `https://${product}.combateafraude.com/`;
    }
    return `https://${domain}.combateafraude.com/`;
  }

  return `https://${productLink}.combateafraude.com/`;
};

export const displayProductsInfosMenu = (product, domain) => {
  switch (product) {
    case 'trust':
      return {
        title: 'Trust Monitor',
        icon: 'caf-ic_processing_line',
        link: renderProductLink('trust', 'left', domain),
      };
    case 'identity':
      return {
        title: 'Identity',
        icon: 'caf-ic_user_active',
        link: renderProductLink('identity', 'left', domain),
      };
    case 'management':
      return {
        title: 'Management',
        icon: 'caf-ic_activity',
        link: renderProductLink('management', 'left', domain),
      };
    default:
      return {
        title: 'utils.renderProducts.displayProductsInfosMenu.default.title',
        icon: 'caf-ic_add',
        link: 'https://www.combateafraude.com/produtos/tour-pela-plataforma',
      };
  }
};

export const displayProductsInfos = (product) => {
  switch (product) {
    case 'trust':
      return {
        title: 'Trust Monitor',
        icon: 'caf-ic_processing_line',
        description: 'utils.renderProducts.displayProductsInfos.trust.description',
      };
    case 'identity':
      return {
        title: 'Identity',
        icon: 'caf-ic_user_active',
        description: 'utils.renderProducts.displayProductsInfos.identity.description',
      };
    case 'management':
      return {
        title: 'Management',
        icon: 'caf-ic_settings',
        description: 'utils.renderProducts.displayProductsInfos.management.description',
      };
    default:
      return {};
  }
};

export const cafProducts = ['trust'];
