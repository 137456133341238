export const displayPermissionsIcon = (permission, icon) => {
  switch (permission?.toLowerCase()) {
    case 'administrador':
      return 'caf-ic_admin';
    case 'comercial':
      return 'caf-ic_awards';
    default:
      return icon;
  }
};
