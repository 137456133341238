import { useState, useCallback } from 'react';

import api from 'services/api';

const useFetch = ({ customApi } = {}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  const clearData = useCallback(() => {
    setData(undefined);
  }, []);

  const get = useCallback(
    async ({ url, config, clearData: clearDataParam }) => {
      try {
        if (clearDataParam) clearData();
        setError(undefined);
        setLoading(true);

        let response;

        if (customApi) {
          response = await customApi.get(url, config);
        } else {
          response = await api.get(url, config);
        }

        setData(response?.data);

        return Promise.resolve(response?.data);
      } catch (err) {
        setError(err?.response?.data || true);
        return Promise.reject(err);
      } finally {
        setLoading(false);
      }
    },
    [clearData, customApi]
  );

  const post = useCallback(
    async ({ url, payload, config }) => {
      try {
        setError(undefined);
        setLoading(true);

        let response;

        if (customApi) {
          response = await customApi.post(url, payload, config);
        } else {
          response = await api.post(url, payload, config);
        }
        setData(response?.data);

        return Promise.resolve(response?.data);
      } catch (err) {
        setError(err?.response?.data || true);
        return Promise.reject(err);
      } finally {
        setLoading(false);
      }
    },
    [customApi]
  );

  const patch = useCallback(
    async ({ url, payload, config }) => {
      try {
        setError(undefined);
        setLoading(true);

        let response;

        if (customApi) {
          response = await customApi.patch(url, payload, config);
        } else {
          response = await api.patch(url, payload, config);
        }
        setData(response?.data);

        return Promise.resolve(response?.data);
      } catch (err) {
        setError(err?.response?.data || true);
        return Promise.reject(err);
      } finally {
        setLoading(false);
      }
    },
    [customApi]
  );

  const put = useCallback(
    async ({ url, payload, config }) => {
      try {
        setError(undefined);
        setLoading(true);

        let response;

        if (customApi) {
          response = await customApi.put(url, payload, config);
        } else {
          response = await api.put(url, payload, config);
        }
        setData(response?.data);

        return Promise.resolve(response?.data);
      } catch (err) {
        setError(err?.response?.data || true);
        return Promise.reject(err);
      } finally {
        setLoading(false);
      }
    },
    [customApi]
  );

  const _delete = useCallback(
    async ({ url, config }) => {
      try {
        setError(undefined);
        setLoading(true);

        let response;

        if (customApi) {
          response = await customApi.delete(url, config);
        } else {
          response = await api.delete(url, config);
        }
        setData(response?.data);

        return Promise.resolve(response?.data);
      } catch (err) {
        setError(err?.response?.data || true);
        return Promise.reject(err);
      } finally {
        setLoading(false);
      }
    },
    [customApi]
  );

  return {
    data,
    error,
    loading,
    clearData,
    get,
    post,
    put,
    patch,
    delete: _delete,
  };
};

export default useFetch;
