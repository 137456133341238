import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import './styles.less';

const { Text } = Typography;

const CustomItem = ({ icon, title, soon }) => {
  const { t: translate } = useTranslation();
  return (
    <div className="custom-menu-item">
      <i className={icon} />
      <div className="infos">
        <Text>
          {title}{' '}
          {soon && (
            <span className="coming-soon">
              {translate('components.dropdown.customItem.text.comingSoon')}
            </span>
          )}
        </Text>
      </div>
    </div>
  );
};

CustomItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  soon: PropTypes.bool,
};

CustomItem.defaultProps = {
  soon: false,
};

export default CustomItem;
