import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Menu, Divider, Dropdown } from 'antd';
import OutsideClickHandler from 'react-outside-click-handler';

import { useAuth } from 'hooks/auth';

import PermissionWrapper from 'routes/PermissionWrapper';
import { hasRequiredPermissions } from 'routes/permissionFunctions';

import CustomItem from 'components/Dropdown/CustomItem';

import pdvTenants from 'utils/skyPDVTenants';

const I18N_BASE_PATH = 'components.header.horizontalNav';

const HorizontalNav = () => {
  const { loggedUser } = useAuth();
  const { t: translate } = useTranslation();

  const [isDropdownProfilesOpened, setIsDropdownProfilesOpened] = useState(false);

  const handleCloseDropDown = useCallback(() => {
    setIsDropdownProfilesOpened(false);
  }, []);

  const toggleDropdownVisible = useCallback(() => {
    setIsDropdownProfilesOpened((oldState) => !oldState);
  }, []);
  const menuDropdown = useMemo(
    () => (
      <>
        <PermissionWrapper requiredPermissions={['users:read']}>
          <Menu style={{ zIndex: 999 }} onSelect={handleCloseDropDown}>
            <Menu.Item key="users">
              <NavLink to="/users-permissions/users">
                <CustomItem
                  icon="caf-ic_person"
                  title={translate(
                    `${I18N_BASE_PATH}.dropdownUsersPermissions.menus.users.title`
                  )}
                />
              </NavLink>
            </Menu.Item>
          </Menu>
        </PermissionWrapper>

        <PermissionWrapper requiredPermissions={['groups:read']}>
          <Menu style={{ zIndex: 999 }} onSelect={handleCloseDropDown}>
            <Menu.Item key="groups">
              <NavLink to="/users-permissions/groups">
                <CustomItem
                  icon="caf-ic_users"
                  title={translate(
                    `${I18N_BASE_PATH}.dropdownUsersPermissions.menus.groups.title`
                  )}
                />
              </NavLink>
            </Menu.Item>
          </Menu>
        </PermissionWrapper>

        <PermissionWrapper requiredPermissions={['people:read']}>
          <Menu style={{ zIndex: 999 }} onSelect={handleCloseDropDown}>
            <Menu.Item key="access-policies">
              <NavLink to="/users-permissions/access-policies">
                <CustomItem
                  icon="caf-ic_lock_valid"
                  title={translate(
                    `${I18N_BASE_PATH}.dropdownUsersPermissions.menus.accessPolicies.title`
                  )}
                />
              </NavLink>
            </Menu.Item>
          </Menu>
        </PermissionWrapper>

        {hasRequiredPermissions(loggedUser, ['users-pdv:read'], []) &&
          pdvTenants?.includes(loggedUser?.tenantId) && (
            <>
              <Divider orientation="left" />
              <Menu style={{ zIndex: 999 }} onSelect={handleCloseDropDown}>
                <Menu.Item key="users-pdv">
                  <NavLink to="/users-permissions/users-pdv?_order=-1&_sort=updatedAt">
                    <CustomItem
                      infoColor
                      icon="caf-ic_person"
                      title={translate(
                        `${I18N_BASE_PATH}.dropdownUsersPermissions.menus.pdvUsers.title`
                      )}
                    />
                  </NavLink>
                </Menu.Item>
              </Menu>
            </>
          )}
      </>
    ),
    [handleCloseDropDown, loggedUser, translate]
  );
  return (
    <>
      {/* <PermissionWrapper requiredPermissions={['metrics:read']}>
      <Menu className="menu-container" mode="horizontal">
        <Menu.Item key="dashboard">
          <NavLink to="/dashboard" className="nav-link" activeClassName="active">
            Visão geral
          </NavLink>
        </Menu.Item>
      </Menu>
    </PermissionWrapper> */}

      <Menu className="menu-container" mode="horizontal">
        <Menu.Item key="registration-info">
          <NavLink to="/registration-info" className="nav-link" activeClassName="active">
            {translate(`${I18N_BASE_PATH}.registrationInfo`)}
          </NavLink>
        </Menu.Item>
        <Menu.Item key="users-permissions">
          <PermissionWrapper requiredPermissions={['users:read']}>
            <OutsideClickHandler
              onOutsideClick={handleCloseDropDown}
              disabled={!isDropdownProfilesOpened}
            >
              <Dropdown
                overlay={menuDropdown}
                overlayClassName="custom-dropdown"
                trigger="click"
                visible={isDropdownProfilesOpened}
                onClick={toggleDropdownVisible}
                zIndex={99999}
              >
                <NavLink
                  to="/users-permissions/"
                  className="nav-link"
                  activeClassName="active"
                >
                  {translate(`${I18N_BASE_PATH}.usersPermissions`)}
                  <i className="caf-ic_arrow_down" style={{ paddingLeft: 3 }} />
                </NavLink>
              </Dropdown>
            </OutsideClickHandler>
          </PermissionWrapper>
        </Menu.Item>

        {/* <PermissionWrapper requiredPermissions={['products:read']}>
      <Menu className="menu-container" mode="horizontal">
        <Menu.Item key="products">
          <NavLink to="/products" className="nav-link" activeClassName="active">
            Produtos
          </NavLink>
        </Menu.Item>
      </Menu>
    </PermissionWrapper> */}
      </Menu>
    </>
  );
};

export default HorizontalNav;
