/* eslint-disable react/prop-types */
import React from 'react';

// import AccessKeysPanel from './AccessKeysPanel';
import ConsumptionPanel from './ConsumptionPanel';
import FacesPanel from './FacesPanel';
import UsersPanel from './UsersPanel';

const TrustPanels = ({ formRef, disabled }) => (
  <>
    <UsersPanel formRef={formRef} disabled={disabled} />
    <FacesPanel formRef={formRef} disabled={disabled} />
    {/* <AccessKeysPanel formRef={formRef} disabled={disabled} /> */}
    <ConsumptionPanel formRef={formRef} disabled={disabled} />
  </>
);

export default TrustPanels;
