/* eslint-disable react/prop-types */
import React from 'react';

import { useTranslation } from 'react-i18next';

import CustomCollapse from '../CustomCollapse';
import PolicyRow from '../PolicyRow';

const I18N_BASE_PATH =
  'pages.private.accessPolicies.components.policiesSelect.actions.components.managementPanels.facesPanel';
// const I18N_BASE_PATH = 'pages.private.mobileFaces';

const FacesPanel = ({ disabled, formRef }) => {
  const { t: translate } = useTranslation();
  return (
    <CustomCollapse
      ruleName="faces"
      disabled={disabled}
      className="no-border-bottom"
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="ic_face"
    >
      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleFaceAuthenticator`)}
        icon="ic_face"
        ruleName="mobile-faces"
        availability={[true, false, false, false]}
      />

      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleFaceset`)}
        icon="ic_criminal"
        ruleName="private-faceset"
        availability={[true, false, true, true]}
      />
    </CustomCollapse>
  );
};

export default FacesPanel;
