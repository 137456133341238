import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Input, Select, message, Spin } from 'antd';
import { PageTitle } from '@combateafraude/react';
import { useParams, useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { useUser } from 'hooks/user';

import { useFetch } from 'services/hooks';

import PoliciesSelect from './components/PoliciesSelect';
import Subheader from './components/Subheader';

import managementPoliciesKeys from './utils/managementPoliciesKeys';
import trustPoliciesKeys from './utils/trustPoliciesKeys';

import Wrapper from '../wrapper';

const listIcons = [
  'caf-ic_anchor',
  'caf-ic_driver',
  'caf-ic_users',
  'caf-ic_motor',
  'caf-ic_eye',
  'caf-ic_person',
  'caf-ic_lawier',
  'caf-ic_admin',
  'caf-ic_face',
  'caf-ic_combateafraude',
  'caf-ic_dollar_sign',
  'caf-ic_law',
];
const permissionsActions = ['read', 'update', 'create', 'delete'];
const products = [
  { name: 'trust', title: 'Trust' },
  { name: 'management', title: 'Management' },
];

const { Option } = Select;

const AccessPolicies = () => {
  const [form] = Form.useForm();
  const { t: translate } = useTranslation();
  const { id: policyId } = useParams();
  const history = useHistory();

  const { refreshUser } = useUser();
  const { loggedUser } = useAuth();

  const { post, put, loading: loadingUpdate } = useFetch();
  const { get: getPolicyEdit, loading: loadingEdit, data: policyData } = useFetch();

  const [selectedProduct, setSelectedProduct] = useState(policyId ? {} : products[0]);
  const [disabled, setDisabled] = useState(!!policyId);

  const handleFinish = useCallback(
    async (payload) => {
      const newPoliciesPayload = [];

      let normalKeys = [...trustPoliciesKeys.normal];
      let specialKeys = Object.keys(trustPoliciesKeys.special);

      if (selectedProduct.name === 'management') {
        normalKeys = [...managementPoliciesKeys.normal];
        specialKeys = [];
      }

      normalKeys?.forEach((key) => {
        if (
          !payload[key]?.read &&
          !payload[key]?.create &&
          !payload[key]?.update &&
          !payload[key]?.delete
        ) {
          return;
        }

        newPoliciesPayload.push({
          name: key,
          effect: 'Allow',
          actions: permissionsActions
            .map((perm) => (payload[key][perm] ? `${key}:${perm}` : null))
            .filter((v) => v !== null),
          resources: [...(payload[key].resources || []), '*'],
          special: false,
        });
      });

      specialKeys.forEach((key) => {
        if (!payload['special-rules'][key]) return;

        newPoliciesPayload.push({ ...trustPoliciesKeys.special[key], name: key });
      });

      try {
        const data = {
          name: payload.name,
          icon: payload.icon,
          tenantId: loggedUser?.tenantId,
          product: selectedProduct?.name,
          policies: newPoliciesPayload,
        };
        let popupMessage = '';

        if (policyId) {
          await put({
            url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/policy-groups/${policyId}`,
            payload: data,
          });
          popupMessage = translate(
            'pages.private.accessPolicies.index.handleFinish.messageSuccessPut'
          );
        } else {
          await post({
            url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/policy-groups`,
            payload: data,
          });
          popupMessage = translate(
            'pages.private.accessPolicies.index.handleFinish.messageSuccessPost'
          );
        }

        history.goBack();
        message.success(popupMessage);
        refreshUser();
      } catch (err) {
        message.error(
          translate('pages.private.accessPolicies.index.handleFinish.messageError')
        );
      }
    },
    [loggedUser, selectedProduct, policyId, history, refreshUser, put, translate, post]
  );

  const setFormValues = useCallback(
    (data) => {
      if (!data) return;

      const formValues = {
        name: data?.name,
        icon: data?.icon,
        product: data?.product,
      };

      data?.policies?.forEach((policy) => {
        if (!policy.special) {
          formValues[policy.name] = {
            execute: policy.actions.includes(`${policy.name}:execute`),
            read: policy.actions.includes(`${policy.name}:read`),
            create: policy.actions.includes(`${policy.name}:create`),
            delete: policy.actions.includes(`${policy.name}:delete`),
            update: policy.actions.includes(`${policy.name}:update`),
            resources: policy.resources?.filter((resource) => resource !== '*'),
          };
        } else {
          formValues['special-rules'] = {
            ...formValues['special-rules'],
            [policy.name]: true,
          };
        }
      });
      const p = products.find((e) => e.name === data?.product);
      form.setFieldsValue(formValues);
      setSelectedProduct(p);
    },
    [form]
  );

  const getPolicy = useCallback(async () => {
    const response = await getPolicyEdit({
      url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/policy-groups/${policyId}`,
      config: {},
    });
    setFormValues(response?.data);
  }, [getPolicyEdit, policyId, setFormValues]);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;
    if (policyId && !policyData) {
      getPolicy();
    }
  }, [getPolicy, policyData, policyId]);

  return (
    <Wrapper
      id="access-policies-create"
      subheader={
        <Subheader
          loading={loadingUpdate}
          formRef={form}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      }
    >
      {selectedProduct?.title ? (
        <PageTitle
          title={selectedProduct?.title}
          subtitle={translate('pages.private.accessPolicies.index.pageTitle.subtitle')}
        />
      ) : (
        <></>
      )}

      <Form layout="vertical" form={form} onFinish={handleFinish}>
        {loadingEdit ? (
          <Spin className="flex center mrg-top-50" />
        ) : (
          <div>
            <Row className="mrg-vertical-30">
              <Col span={8}>
                <Form.Item
                  label={translate('pages.private.accessPolicies.index.name')}
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={translate('pages.private.accessPolicies.index.product')}
                  name="product"
                  rules={[{ required: true }]}
                  initialValue="trust"
                >
                  <Select
                    disabled={policyId}
                    onSelect={(value) => {
                      const p = products.find((e) => e.name === value);
                      setSelectedProduct(p);
                    }}
                  >
                    {products.map((pr) => (
                      <Option value={pr?.name}>{pr?.title}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item
                  label={translate('pages.private.accessPolicies.index.icon')}
                  name="icon"
                  rules={[{ required: true }]}
                >
                  <Select disabled={disabled}>
                    {listIcons.map((icon) => (
                      <Option value={icon}>
                        <i className={`${icon} font-size-20 mrg-horizon-5`} />
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <PoliciesSelect
              formRef={form}
              disabled={disabled}
              selectedProduct={selectedProduct.name}
            />
          </div>
        )}
      </Form>
    </Wrapper>
  );
};

export default AccessPolicies;
