import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Menu } from 'antd';
import { useAuth } from 'hooks/auth';
import { hasRequiredPermissions } from 'routes/permissionFunctions';
import useDeleteFaceModal from './DeleteFace';

import './styles.less';

const ImageCard = ({ _id, image, title, subtitle, refreshList }) => {
  const { t: translate } = useTranslation();
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const { loggedUser } = useAuth();
  const { openModal: openDeleteFaceModal, DeleteFaceModal } = useDeleteFaceModal({
    _id,
    refreshList,
    loggedUser,
  });
  const domain = window.location.href;

  const userHasDeletePermission = useMemo(() => {
    const hasPermission = hasRequiredPermissions(
      loggedUser,
      ['private-faceset:delete'],
      [],
      true
    );

    if (domain.includes('mobile-faces')) return false;
    return hasPermission;
  }, [domain, loggedUser]);

  const menuDelete = useMemo(
    () => (
      <>
        {DeleteFaceModal}
        <Menu>
          <Menu.Item key="delete-face">
            <button
              type="button"
              className="button-delete reset-btn"
              onClick={openDeleteFaceModal}
            >
              <i className="caf-ic_trash" />
              <span>
                {translate('components.imageCard.menuDelete.button.deleteButton')}
              </span>
            </button>
          </Menu.Item>
        </Menu>
      </>
    ),
    [DeleteFaceModal, openDeleteFaceModal, translate]
  );

  return (
    <div className="image-card-container">
      <img className="image" src={image} alt={title || 'image'} />
      <div className="content-wrapper">
        {userHasDeletePermission && (
          <div>
            <OutsideClickHandler
              onOutsideClick={() => setIsDropdownOpened(false)}
              disabled={!isDropdownOpened}
            >
              <Dropdown
                overlay={menuDelete}
                trigger={['click']}
                visible={isDropdownOpened}
                placement="bottomRight"
              >
                <Button
                  className="ant-button"
                  type="link"
                  onClick={() => setIsDropdownOpened((oldState) => !oldState)}
                >
                  <i className="caf-ic_more-horizontal" />
                </Button>
              </Dropdown>
            </OutsideClickHandler>
          </div>
        )}
        <p className="title">{title}</p>
        <p>{subtitle}</p>
      </div>
    </div>
  );
};

ImageCard.propTypes = {
  _id: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  refreshList: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
};

ImageCard.defaultProps = {
  _id: undefined,
  image: '',
  title: undefined,
  subtitle: undefined,
};

export default ImageCard;
