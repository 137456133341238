import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';

const { Option } = Select;

const LanguageSelector = () => {
  const { t: translate } = useTranslation();

  return (
    <Form.Item
      label={translate('components.i18n.index.form.label')}
      name="language"
      className="preferred-language"
    >
      <Select>
        <Option value="pt-BR">Português (Brasil) </Option>
        <Option value="en-US">English</Option>
      </Select>
    </Form.Item>
  );
};

export default LanguageSelector;
