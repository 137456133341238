import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';

import { hasRequiredPermissions } from 'routes/permissionFunctions';
import PermissionWrapper from 'routes/PermissionWrapper';
import { useAuth } from 'hooks/auth';

import useRemoveGroup from './RemoveGroup';

const GroupsActionsList = ({ group, refreshListRef }) => {
  const { t: translate } = useTranslation();
  const { openModal, RemoveGroupModal } = useRemoveGroup({ group, refreshListRef });
  const { loggedUser } = useAuth();

  return (
    <>
      {RemoveGroupModal}
      <PermissionWrapper or requiredPermissions={['groups:update', 'groups:delete']}>
        <Dropdown
          overlay={
            <Menu className="pdd-vertical-5">
              {hasRequiredPermissions(loggedUser, ['groups:update'], []) && (
                <Menu.Item key="editGroup" className="text-dark">
                  <Link to={`/users-permissions/groups/${group?.id}`}>
                    <i className="caf-ic_edit mrg-right-10" />
                    <span className="text-semibold">
                      {translate(
                        'pages.private.users.components.groupsActions.groupsActionsList.editGroup'
                      )}
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {hasRequiredPermissions(
                loggedUser,
                ['groups:delete', 'groups:update'],
                []
              ) && <Menu.Divider className="mrg-horizon-15 mrg-vertical-5" />}

              {hasRequiredPermissions(loggedUser, ['groups:delete'], []) && (
                <Menu.Item key="deleteGroup" className="text-danger" onClick={openModal}>
                  <i className="caf-ic_trash mrg-right-10" />
                  <span className="text-semibold">
                    {translate(
                      'pages.private.users.components.groupsActions.groupsActionsList.deleteGroup'
                    )}
                  </span>
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon btn-more-icon-hover">
            <i className="caf-ic_more-vertical" />
          </div>
        </Dropdown>
      </PermissionWrapper>
    </>
  );
};

GroupsActionsList.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    product: PropTypes.string,
  }),
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

GroupsActionsList.defaultProps = {
  group: {},
};

export default GroupsActionsList;
