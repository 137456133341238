import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Modal, Input, Upload } from 'antd';

import { useAuth } from 'hooks/auth';

import { cleanApi } from 'services/api';
import { useFetch } from 'services/hooks';

import { Button, PageTitle } from '@combateafraude/react';
import InputMask from 'components/Form/InputMask';

const I18N_BASE_PATH = 'pages.private.privateFaceset.modalNewFace';

const ModalNewFace = ({ refreshListRef }) => {
  const { t: translate } = useTranslation();
  const [form] = Form.useForm();
  const { post, loading } = useFetch({ customApi: cleanApi });
  const { loggedUser } = useAuth();

  const [filesList, setFilesList] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);

  const token = useMemo(() => loggedUser?.accountData?.products?.trust?.cafAccessToken, [
    loggedUser,
  ]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    form.resetFields();
    setFilesList([]);
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const submit = useCallback(
    async (data) => {
      try {
        const payload = { ...data, images: filesList };

        await post({
          url: `${process.env.REACT_APP_BASE_URL_CAF_API}/private-faceset`,
          payload,
          config: {
            params: {
              token,
            },
          },
        });

        refreshListRef.current();
        closeModal();
      } catch (error) {
        // silencer
      }
    },
    [closeModal, filesList, post, refreshListRef, token]
  );

  const handleBeforeUploadImage = useCallback(
    (file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64 = e?.target?.result;
        setFilesList((oldState) => [...oldState, String(base64)]);
        const parsedBase64Array = String(base64).split(',');
        parsedBase64Array.shift();
        form.setFieldsValue({ image: parsedBase64Array.join('') });
      };

      reader.readAsDataURL(file);

      return false;
    },
    [form]
  );

  const modalContent = useMemo(
    () => (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        width={540}
        destroyOnClose
      >
        <div className="no-mrg no-pdd">
          <PageTitle title={translate(`${I18N_BASE_PATH}.title`)} />

          <Form form={form} layout="vertical" onFinish={submit}>
            <Row className="mrg-top-5">
              <Col span={24}>
                <Form.Item name="cpf" label={translate(`${I18N_BASE_PATH}.form.cpf`)}>
                  <InputMask
                    mask={translate(`${I18N_BASE_PATH}.form.cpfMask`)}
                    className="uppercase-input"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="mrg-top-5">
              <Col span={24}>
                <Form.Item
                  name="description"
                  label={translate(`${I18N_BASE_PATH}.form.reason`)}
                  rules={[{ required: true }]}
                >
                  <Input.TextArea showCount maxLength={256} />
                </Form.Item>
              </Col>
            </Row>

            <Upload
              listType="picture-card"
              beforeUpload={handleBeforeUploadImage}
              accept="image/*"
            >
              <span className="pdd-horizon-10">
                {translate(`${I18N_BASE_PATH}.form.add`)}
              </span>
            </Upload>

            <div className="gx-flex-row gx-justify-content-end mrg-top-20">
              <Button
                type="default"
                onClick={closeModal}
                className="btn-cancel"
                loading={loading}
                disabled={loading}
              >
                {translate(`${I18N_BASE_PATH}.button.cancel`)}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                {translate(`${I18N_BASE_PATH}.button.register`)}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [form, modalVisible, closeModal, submit, handleBeforeUploadImage, translate, loading]
  );

  return { openModal, closeModal, modalContent };
};

export default ModalNewFace;
