import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import CAFLoader from 'components/CAFLoader';

import { useAuth } from 'hooks/auth';
import { useTheme } from 'hooks/theme';

import { hasRequiredPermissions } from './permissionFunctions';

const RouteWrapper = ({
  isPrivate,
  component: Component,
  or,
  requiredPermissions,
  ...rest
}) => {
  const { loggedUser, loadingAuth, getLoggedUser } = useAuth();
  const { loadingTheme, changeTheme, domain } = useTheme();

  useEffect(() => {
    if (loggedUser) return;

    getLoggedUser().then(({ accountData }) => {
      changeTheme(accountData?.domain);
    });
  }, [getLoggedUser, loggedUser, changeTheme]);

  const isLoading = useMemo(() => loadingAuth || loadingTheme, [
    loadingAuth,
    loadingTheme,
  ]);

  return isLoading ? (
    <CAFLoader />
  ) : (
    <Route
      {...rest}
      render={({ location }) => {
        if (isPrivate && !loggedUser) {
          const redirectUri = encodeURIComponent(
            `${process.env.REACT_APP_BASE_URL_MANAGEMENT_WEB}${location.pathname}${location.search}`
          );
          window.location.href = `${process.env.REACT_APP_BASE_URL_AUTH_WEB}/?service=management&env=${process.env.REACT_APP_ENV}&continue=${redirectUri}&domain=${domain}`;
          return <></>;
        }

        if (requiredPermissions && requiredPermissions.length > 0) {
          const authorized = hasRequiredPermissions(loggedUser, requiredPermissions, [], or); // prettier-ignore

          if (!authorized) {
            return <Redirect to="/unauthorized" />;
          }
        }

        return <Component />;
      }}
    />
  );
};

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  or: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  or: false,
  requiredPermissions: [],
};

export default RouteWrapper;
