import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import _ from 'lodash';

import { PageTitle } from '@combateafraude/react';
import { useComponentList } from 'components/List';
import SearchInput from 'components/Form/SearchInput';

import CardLink from './components/Card';

const I18N_BASE_PATH = 'pages.private.mobileFaces';

const MobileFaces = () => {
  const { t: translate } = useTranslation();

  const [query] = useQueryParams({
    _filter: StringParam,
  });
  const { componentList, setFilterData } = useComponentList({
    component: CardLink,
    rowKey: '_id',
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/mobile-faces`,
      config: {
        params: {},
      },
    },
    queryParams: {
      _filter: StringParam,
    },
  });

  const handleFormChange = _.debounce((__, values) => {
    setFilterData({
      ...values,
      _filter: values._filter ? values._filter.trim() : undefined,
    });
  }, 500);

  return (
    <div>
      <Row align="middle" className="flex no-mrg">
        <Col className="flex-1 page-title">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.pageTitle.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.pageTitle.subtitle`)}
          />
        </Col>
        <Col className="flex end flex-1">
          <Form
            layout="inline"
            onValuesChange={handleFormChange}
            initialValues={{ _filter: query?._filter }}
          >
            <SearchInput
              name="_filter"
              placeholder={translate(
                `${I18N_BASE_PATH}.pageTitle.form.search.placeholder`
              )}
              style={{ minWidth: 350 }}
            />
          </Form>
        </Col>
      </Row>

      <div>{componentList}</div>
    </div>
  );
};

export default MobileFaces;
