import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Divider, Popconfirm, message, Tooltip } from 'antd';
import { Button } from '@combateafraude/react';

import api from 'services/api';

import PermissionWrapper from 'routes/PermissionWrapper';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import Card from 'components/Card/Standard';
import HiddenParagraph from 'components/HiddenParagraph';

import { findMobileProduct } from 'pages/private/AccessKeys/utils/mobileProducts';

import './styles.less';

const { Title, Text } = Typography;

const AccessKeysCard = ({ data, refreshList }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const [loadingRemoveAccessKey, setLoadingRemoveAccessKey] = useState(false);
  const [isPopConfirmVisible, setIsPopConfirmVisible] = useState(false);
  const onRemoveAccessKey = useCallback(async () => {
    try {
      setLoadingRemoveAccessKey(true);

      await api.delete(
        `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/access-keys/${data?.clientId}`,
        { params: { tenantId: data?.tenantId } }
      );
      refreshList();
      message.success(
        translate(
          'pages.private.accessKeys.components.accessKeysCard.onRemoveAccessKey.messageSuccess'
        )
      );
    } catch (error) {
      message.error(
        translate(
          'pages.private.accessKeys.components.accessKeysCard.onRemoveAccessKey.messageError'
        )
      );
    } finally {
      setLoadingRemoveAccessKey(false);
      setIsPopConfirmVisible(false);
    }
  }, [data, refreshList, translate]);

  return (
    <Card id="access-keys-card-component">
      <Row className="no-pdd no-mrg">
        <Col span={6} className="cells">
          <Title level={4} className="no-mrg">
            {translate('pages.private.accessKeys.components.accessKeysCard.clientId')}
          </Title>
          <HiddenParagraph text={data?.clientId} copyable />
        </Col>
        <Col span={11} className="cells border-left">
          <Title level={4} className="no-mrg">
            {translate('pages.private.accessKeys.components.accessKeysCard.clientSecret')}
          </Title>
          <HiddenParagraph text={data?.clientSecret} hidden copyable />
        </Col>
        <Col span={5} className="cells border-left">
          <Title level={4} className="no-mrg">
            {translate('pages.private.accessKeys.components.accessKeysCard.createdAt')}
          </Title>
          <Text>{i18nFormatDate(data?.createdAt || new Date(), 'default')}</Text>
        </Col>
        <PermissionWrapper requiredPermissions={['access-keys:delete']}>
          <Col span={2} className="border-left flex center">
            <Popconfirm
              trigger={['click']}
              title={translate(
                'pages.private.accessKeys.components.accessKeysCard.popConfirm.title'
              )}
              okText={translate(
                'pages.private.accessKeys.components.accessKeysCard.popConfirm.okText'
              )}
              cancelText={translate(
                'pages.private.accessKeys.components.accessKeysCard.popConfirm.cancelText'
              )}
              okButtonProps={{
                type: 'danger',
                ghost: true,
                disabled: loadingRemoveAccessKey,
                loading: loadingRemoveAccessKey,
              }}
              cancelButtonProps={{ disabled: loadingRemoveAccessKey }}
              onConfirm={() => {
                setIsPopConfirmVisible(true);
                onRemoveAccessKey();
              }}
              overlayClassName="custom-pop-confirm"
              visible={isPopConfirmVisible}
              onVisibleChange={(e) => {
                if (!loadingRemoveAccessKey) {
                  setIsPopConfirmVisible(e);
                }
              }}
            >
              <Button type="danger">
                <i className="caf-ic_trash" />
              </Button>
            </Popconfirm>
          </Col>
        </PermissionWrapper>
      </Row>

      <Divider />

      {data?.policies &&
        data.policies.map((p) => {
          const product = findMobileProduct(p?.name);
          return (
            <Tooltip
              key={product?.name}
              placement="top"
              title={translate(product?.description)}
            >
              <div className="validation-tag">
                {product?.icon && <i className={`${product.icon} mrg-right-10 no-pdd`} />}
                {translate(product?.title)}
              </div>
            </Tooltip>
          );
        })}
    </Card>
  );
};

AccessKeysCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
    tenantId: PropTypes.string,
    createdAt: PropTypes.string,
    policies: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  refreshList: PropTypes.func.isRequired,
};

AccessKeysCard.defaultProps = {
  data: undefined,
};

export default AccessKeysCard;
