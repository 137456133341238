import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

// import Dashboard from 'pages/private/Dashboard';
import Consumptions from 'pages/private/Consumptions';
import Users from 'pages/private/Users/components/UsersList';
import UsersPDV from 'pages/private/Users/UsersPDV';
import PoliciesList from 'pages/private/Users/components/PoliciesList';
import GroupsList from 'pages/private/Users/components/GroupsList';
import Groups from 'pages/private/Groups';
import AccessPolicies from 'pages/private/AccessPolicies';
import Faces from 'pages/private/Faces';
// import Products from 'pages/private/Products';
import AccessKeys from 'pages/private/AccessKeys';
import RegistrationInfo from 'pages/private/Registration/RegistrationInfo';
import OnboardingBuilder from 'pages/private/OnboardingBuilder';

import { ConfigProvider } from 'antd';

import Unauthorized from 'pages/private/Unauthorized';

import pdvTenants from 'utils/skyPDVTenants';

import { useAuth } from 'hooks/auth';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import Route from './Route';

const Routes = () => {
  const { loggedUser } = useAuth();
  const { i18nSetAntFormValidateMessages, i18nSetAntLocale } = useI18nFormatters();

  const formValidateMessages = i18nSetAntFormValidateMessages();
  const locale = i18nSetAntLocale();

  return (
    <ConfigProvider form={{ validateMessages: formValidateMessages }} locale={locale}>
      <Switch>
        {/* <Route
      path="/dashboard"
      exact
      component={Dashboard}
      isPrivate
      requiredPermissions={['metrics:read']}
    /> */}

        <Route
          path="/consumption"
          exact
          component={Consumptions}
          isPrivate
          requiredPermissions={['consumption:read']}
        />

        {pdvTenants?.includes(loggedUser?.tenantId) && (
          <Route
            path="/users-permissions/users-pdv"
            exact
            component={UsersPDV}
            isPrivate
            requiredPermissions={['users-pdv:read']}
          />
        )}

        <Route
          path="/users-permissions/users"
          exact
          component={Users}
          isPrivate
          requiredPermissions={['users:read']}
          or
        />

        <Route
          path="/users-permissions/groups"
          exact
          component={GroupsList}
          isPrivate
          requiredPermissions={['groups:read']}
        />

        <Route
          path="/users-permissions/groups/:id"
          exact
          component={Groups}
          isPrivate
          requiredPermissions={['groups:read']}
        />

        <Route
          path="/users-permissions/access-policies"
          exact
          component={PoliciesList}
          isPrivate
          requiredPermissions={['permissions:read']}
        />

        {/* <Route
      path="/products"
      exact
      component={Products}
      isPrivate
      requiredPermissions={['products:read']}
    /> */}

        <Route
          path="/users-permissions/access-policies/create"
          exact
          component={AccessPolicies}
          isPrivate
          requiredPermissions={['permissions:create']}
        />

        <Route
          path="/users-permissions/access-policies/:id"
          exact
          component={AccessPolicies}
          isPrivate
          requiredPermissions={['permissions:update']}
        />
        <Route
          path="/faces-base/private-faceset"
          exact
          component={Faces}
          isPrivate
          requiredPermissions={['private-faceset:read']}
        />
        <Route
          path="/faces-base/mobile-faces"
          exact
          component={Faces}
          isPrivate
          requiredPermissions={['mobile-faces:read']}
        />

        <Route
          path="/access-keys"
          exact
          component={AccessKeys}
          isPrivate
          requiredPermissions={['access-keys:read']}
        />
        <Route
          path="/registration-info"
          exact
          component={RegistrationInfo}
          isPrivate
          requiredPermissions={['users:read']}
        />
        <Route path="/onboarding-builder" exact component={OnboardingBuilder} isPrivate />

        <Route path="/unauthorized" exact component={Unauthorized} isPrivate />

        <Redirect to="/registration-info" />
      </Switch>
    </ConfigProvider>
  );
};

export default Routes;
