import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';
import { Button } from '@combateafraude/react';
import { hasRequiredPermissions } from 'routes/permissionFunctions';
import { useAuth } from 'hooks/auth';

import { useGroup } from 'hooks/group';

import ProductCard from 'components/Card/Product';

import PermissionsList from './PermissionsList';

const PermissionsGroup = ({ product, permissions }) => {
  const { t: translate } = useTranslation();
  const { group } = useGroup();
  const { loggedUser } = useAuth();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [groupPermissions, setGroupPermissions] = useState({});

  const closeDropdown = useCallback(() => {
    setIsDropdownVisible(false);
  }, []);

  useEffect(() => {
    const currentProducts = group?.policyGroups || {};
    const currentProductId = currentProducts[product] || {};
    const currentProduct = permissions.find((p) => p?.id === currentProductId);
    setGroupPermissions({
      id: currentProduct?.id,
      name: currentProduct?.name,
    });
  }, [group, permissions, product]);

  return (
    <ProductCard
      product={product}
      selected={isDropdownVisible}
      className="custom-pdd-btm"
      footer={
        <Dropdown
          overlay={
            <PermissionsList
              product={product}
              permissions={permissions}
              groupPermissions={groupPermissions}
              closeDropdown={closeDropdown}
              setGroupPermissions={setGroupPermissions}
            />
          }
          trigger={['click']}
          placement="bottomCenter"
          visible={isDropdownVisible}
          disabled={!hasRequiredPermissions(loggedUser, ['users:update'], [])}
          onVisibleChange={() => setIsDropdownVisible((oldState) => !oldState)}
        >
          <Button
            type="text"
            rounded={false}
            className="flex align-center mrg-top-5 no-pdd-horizon no-background"
          >
            <span className="text-dark text-semibold">
              {groupPermissions?.name ||
                translate(
                  'pages.private.groups.components.groupContent.permissionsGroup.index.noAccess'
                )}
            </span>
            <i className="caf-ic_arrow_down mrg-left-5" />
          </Button>
        </Dropdown>
      }
    />
  );
};

PermissionsGroup.propTypes = {
  product: PropTypes.oneOf(['trust', 'management', 'identity']).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PermissionsGroup;
