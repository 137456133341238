import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Menu, Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';

import { displayPermissionsIcon } from 'utils/renderPermissions';

import useUpdateGroupPermissions from '../../GroupActions/UpdateGroupPermissions';
// import './styles.less';

const PermissionsList = ({
  product,
  permissions,
  groupPermissions,
  closeDropdown,
  setGroupPermissions,
}) => {
  const { openModal, UpdateGroupPermissionsModal } = useUpdateGroupPermissions({
    setGroupPermissions,
  });
  const { t: translate } = useTranslation();
  const history = useHistory();

  return (
    <>
      {UpdateGroupPermissionsModal}

      <Menu className="pdd-vertical-5 permissions-list">
        {permissions.length ? (
          permissions
            .sort((a, b) => a?.name?.localeCompare(b?.name))
            .map((p) =>
              p?.tenantId === 'combateafraude' ? (
                <Menu.Item
                  key={p?.id}
                  className="text-dark"
                  disabled={p?.name === groupPermissions?.name}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    onClick={() => {
                      openModal(p);
                      closeDropdown();
                    }}
                    aria-hidden="true"
                    style={{ marginRight: 40 }}
                  >
                    <i
                      className={`${displayPermissionsIcon(
                        p?.name,
                        p?.icon
                      )} mrg-right-10}`}
                    />
                    <span className="text-semibold">{p?.name}</span>
                  </div>
                </Menu.Item>
              ) : (
                <></>
              )
            )
        ) : (
          <></>
        )}
        <Menu.Item
          className="text-dark"
          disabled={!groupPermissions?.name}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            onClick={() => {
              openModal({
                id: '',
                name: translate(
                  'pages.private.groups.components.groupContent.permissionsGroup.permissionsList.noAccess'
                ),
                product,
              });
              closeDropdown();
            }}
            aria-hidden="true"
            style={{ marginRight: 40 }}
          >
            <i className="caf-ic_x_close mrg-right-10" />
            <span className="text-semibold">
              {translate(
                'pages.private.groups.components.groupContent.permissionsGroup.permissionsList.noAccess'
              )}
            </span>
          </div>
        </Menu.Item>
        <Menu.Divider />
        {permissions.length ? (
          <>
            {permissions
              .sort((a, b) => a?.name?.localeCompare(b?.name))
              .map((p) =>
                p?.tenantId !== 'combateafraude' ? (
                  <>
                    <Menu.Item key={p?.id} className="text-dark">
                      <Row className="no-mrg no-pdd">
                        <Col flex="auto">
                          {p?.name !== groupPermissions?.name ? (
                            <div
                              onClick={() => {
                                openModal(p);
                                closeDropdown();
                              }}
                              aria-hidden="true"
                              style={{ marginRight: 40 }}
                            >
                              <i
                                className={`${displayPermissionsIcon(
                                  p?.name,
                                  p?.icon
                                )} mrg-right-10`}
                              />
                              <span className="text-semibold">{p?.name}</span>
                            </div>
                          ) : (
                            <div
                              aria-hidden="true"
                              style={{ color: 'rgba(0, 0, 0, 0.25)', marginRight: 40 }}
                            >
                              <i
                                className={`${displayPermissionsIcon(
                                  p?.name,
                                  p?.icon
                                )} mrg-right-10`}
                              />
                              <span className="text-semibold">{p?.name}</span>
                            </div>
                          )}
                        </Col>
                        <Col flex="18px">
                          <i
                            aria-hidden="true"
                            className="caf-ic_edit font-size-18"
                            style={{ margin: 0 }}
                            onClick={() => {
                              history.push(`/users-permissions/access-policies/${p?.id}`);
                            }}
                          />
                        </Col>
                      </Row>
                    </Menu.Item>
                  </>
                ) : (
                  <></>
                )
              )}
          </>
        ) : (
          <div className="flex flex-1 center padding-15">
            <span className="text-dark font-size-14">
              {translate(
                'pages.private.groups.components.groupContent.permissionsGroup.permissionsList.noRulesAvailable'
              )}
            </span>
          </div>
        )}
        <Menu.Divider />
        <Menu.Item
          className="text-dark"
          onClick={() => {
            history.push(`/users-permissions/access-policies/create`);
          }}
        >
          <i className="caf-ic_add mrg-right-10" />
          <span className="text-semibold">
            {translate(
              'pages.private.groups.components.groupContent.permissionsGroup.permissionsList.newRule'
            )}
          </span>
        </Menu.Item>
      </Menu>
    </>
  );
};

PermissionsList.propTypes = {
  product: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.shape({})),
  groupPermissions: PropTypes.shape({ name: PropTypes.string }).isRequired,
  closeDropdown: PropTypes.func.isRequired,
  setGroupPermissions: PropTypes.func.isRequired,
};

PermissionsList.defaultProps = {
  permissions: [],
};

export default PermissionsList;
