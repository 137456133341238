import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Result, Typography } from 'antd';

import Icon from './Icon';

import './styles.less';

const { Title } = Typography;

const RequestSuccess = ({ title, subtitle, footer, className }) => (
  <Row id="request-success-component" className={className}>
    <Col span={24}>
      <Result
        status="success"
        icon={<Icon />}
        title={<Title level={1}>{title}</Title>}
        subTitle={subtitle && <span>{subtitle}</span>}
      />
    </Col>

    {footer}
  </Row>
);

RequestSuccess.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  footer: PropTypes.node,
  className: PropTypes.string,
};

RequestSuccess.defaultProps = {
  subtitle: undefined,
  footer: <></>,
  className: '',
};

export default RequestSuccess;
