import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Typography } from 'antd';

import { displayProductsInfos } from 'utils/renderProducts';

import './styles.less';

const { Title } = Typography;

const ProductCard = ({ product, selected, className, footer }) => {
  const { t: translate } = useTranslation();

  return (
    <Card
      id="product-card-component"
      className={`${className} ${selected ? 'selected' : 'default'}`}
    >
      <div className="icon-container flex center mrg-right-15">
        <i className={`${displayProductsInfos(product)?.icon} font-size-26`} />
      </div>

      <div>
        <Title level={5} className="text-dark text-semibold font-size-16">
          {displayProductsInfos(product)?.title}
        </Title>
        <span className="display-block text-default">
          {translate(displayProductsInfos(product)?.description)}
        </span>

        {footer}
      </div>
    </Card>
  );
};

ProductCard.propTypes = {
  product: PropTypes.oneOf(['trust', 'management', 'identity']).isRequired,
  selected: PropTypes.bool,
  className: PropTypes.string,
  footer: PropTypes.node,
};

ProductCard.defaultProps = {
  selected: false,
  className: '',
  footer: <></>,
};

export default ProductCard;
