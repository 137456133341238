import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Tooltip } from 'antd';

const HeaderInfo = () => {
  const { t: translate } = useTranslation();

  return (
    <Row className="no-mrg text-bold gx-100 flex center vertical-align">
      <Col flex="auto" />
      <Col flex="40px">
        <Tooltip
          title={translate(
            'pages.private.accessPolicies.components.policiesSelect.actions.read'
          )}
        >
          <i className="caf-ic_eye font-size-20" />
        </Tooltip>
      </Col>
      <Col flex="40px">
        <Tooltip
          title={translate(
            'pages.private.accessPolicies.components.policiesSelect.actions.update'
          )}
        >
          <i className="caf-ic_edit font-size-20" />
        </Tooltip>
      </Col>
      <Col flex="40px">
        <Tooltip
          title={translate(
            'pages.private.accessPolicies.components.policiesSelect.actions.create'
          )}
        >
          <i className="caf-ic_add font-size-20" />
        </Tooltip>
      </Col>
      <Col flex="40px">
        <Tooltip
          title={translate(
            'pages.private.accessPolicies.components.policiesSelect.actions.delete'
          )}
        >
          <i className="caf-ic_trash font-size-20" />
        </Tooltip>
      </Col>
    </Row>
  );
};

export default HeaderInfo;
