import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Breadcrumb, Tooltip, Typography } from 'antd';
import { Button, Tag } from '@combateafraude/react';

import { useAuth } from 'hooks/auth';
import { useGroup } from 'hooks/group';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useScroll } from 'hooks/scroll';

// import profileDefault from 'assets/images/profile-default.png';

import PermissionWrapper from 'routes/PermissionWrapper';
import useUpdateGroup from '../GroupActions/UpdateGroup';

import './styles.less';

const { Title } = Typography;

const GroupSubheader = () => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const { loggedUser } = useAuth();
  const { group, loading } = useGroup();
  const { isAtTop } = useScroll();

  const { openModal, UpdateGroupModal } = useUpdateGroup();

  const isAtTopClass = useMemo(() => (isAtTop ? 'expand' : 'retract'), [isAtTop]);

  const hasImage = useMemo(() => false, []);

  const standardBreadcrumbs = useMemo(
    () => (
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link className="gx-link" to="/users-permissions/groups">
            {translate(
              'pages.private.groups.components.groupSubheader.index.standardBreadcumbs.groups'
            )}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {translate(
            'pages.private.groups.components.groupSubheader.index.standardBreadcumbs.details'
          )}
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    [translate]
  );

  return (
    <>
      {UpdateGroupModal}

      <section id="subheader-group-component">
        <div
          className={`gx-header-horizontal-top ${isAtTopClass}
        ${hasImage && 'custom-horizontal-pdd'}`}
        >
          <div className="gx-container">
            <div className="subheader-content">
              <div
                className={`subheader-info ${isAtTopClass}
              ${hasImage && 'custom-breadcrumbs'}`}
              >
                {standardBreadcrumbs}
                <div className="flex align-center">
                  <Title level={5} className="subheader-name max-lines-2">
                    {group?.name || '-'}
                  </Title>
                  {group?.id === loggedUser?.id && (
                    <Tag className="mrg-left-10 tag-you">
                      {translate(
                        'pages.private.groups.components.groupSubheader.index.yourGroup'
                      )}
                    </Tag>
                  )}
                </div>
                <span className="subheader-last-update">
                  {translate(
                    'pages.private.groups.components.groupSubheader.index.lastValidation'
                  )}{' '}
                  {i18nFormatDate(group?.updatedAt || group?.createdAt, 'default')}
                </span>
              </div>
              <PermissionWrapper requiredPermissions={['users:update']}>
                <Tooltip placement="top" title="Editar perfil">
                  <Button
                    className="subheader-update-profile"
                    onClick={openModal}
                    disabled={!group || loading}
                  >
                    <i className="caf-ic_edit" />
                  </Button>
                </Tooltip>
              </PermissionWrapper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GroupSubheader;
