/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef } from 'react';
import { Form, Select } from 'antd';

import { useFetch } from 'services/hooks';

import { useTranslation } from 'react-i18next';
import CustomCollapse from '../CustomCollapse';
import PolicyRow from '../PolicyRow';

const { Option, OptGroup } = Select;

const I18N_BASE_PATH =
  'pages.private.accessPolicies.components.policiesSelect.actions.components.trustPanels.rulesPanel';

const ProfileTemplatesPanel = ({ disabled, formRef }) => {
  const { t: translate } = useTranslation();

  const {
    get: getProfileTemplates,
    data: profileTemplates,
    loading: loadingProfileTemplates,
  } = useFetch();

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;
    getProfileTemplates({
      url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/profile-templates`,
    });
  }, [getProfileTemplates]);

  const ProfileTemplatesResources = useMemo(() => {
    const getOptions = (type) => (
      <OptGroup label={type === 'PF' ? 'Pessoa Física' : 'Pessoa Jurídica'}>
        {profileTemplates?.docs?.map(
          (action) =>
            action.type === type && (
              <Option value={`profile-templates:${action._id}`}>{action.name}</Option>
            )
        )}
      </OptGroup>
    );

    return (
      <Form.Item name={['profile-templates', 'resources']} className="no-mrg no-pdd">
        <Select
          disabled={disabled || loadingProfileTemplates}
          mode="multiple"
          optionFilterProp="children"
          loading={loadingProfileTemplates}
          allowClear
          maxTagCount={3}
        >
          {getOptions('PF')}
          {getOptions('PJ')}
        </Select>
      </Form.Item>
    );
  }, [disabled, loadingProfileTemplates, profileTemplates]);

  return (
    <CustomCollapse
      ruleName="profile-templates"
      disabled={disabled}
      className="no-border-top no-border-bottom"
      title={translate(`${I18N_BASE_PATH}.policyRows.titleQueryTemplates`)}
      icon="ic_admin"
    >
      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleQueryTemplates`)}
        icon="ic_face"
        ruleName="profile-templates"
        availability={[true, true, true, true]}
        ResourcesComponent={ProfileTemplatesResources}
      />

      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleOnboardingLinks`)}
        icon="ic_link"
        ruleName="onboardings"
        availability={[true, true, true, false]}
      />
    </CustomCollapse>
  );
};

export default ProfileTemplatesPanel;
