export const createRegexFor = (policyOrResource) =>
  policyOrResource === '*' || policyOrResource === '*:*'
    ? new RegExp('.*')
    : new RegExp(policyOrResource);

export const mapUserPolicies = (policies) =>
  policies.reduce((acc, cur) => {
    cur.actions.forEach((action) => {
      cur.resources.forEach((resource) => {
        acc.push({ effect: cur.effect, action, resource });
      });
    });
    return acc;
  }, []);

export const hasRequiredPermissions = (
  user,
  requiredActions,
  requiredResources,
  or = false
) => {
  const userPolicies = user?.products?.management?.policyGroupData?.policies || [];
  const mappedUserPolicies = mapUserPolicies(userPolicies);

  const requiredPermissions = [];

  requiredActions.forEach((action) =>
    requiredResources.length > 0
      ? requiredResources.forEach((resource) =>
          requiredPermissions.push({ action, resource })
        )
      : requiredPermissions.push({ action, resource: '' })
  );

  const newRequiredPermissions = requiredPermissions.reduce((acc, cur) => {
    const compatibleActions = mappedUserPolicies.filter((x) =>
      createRegexFor(x.action).test(cur.action)
    );

    const compatibleDeniedResources = compatibleActions.filter(
      (x) => createRegexFor(x.resource).test(cur.resource) && x.effect === 'Deny'
    );

    const compatibleAllowedResources = compatibleActions.filter(
      (x) => createRegexFor(x.resource).test(cur.resource) && x.effect === 'Allow'
    );

    if (compatibleActions.length === 0) {
      acc.push(cur);
    } else if (compatibleDeniedResources.length > 0) {
      acc.push(cur);
    } else if (compatibleAllowedResources.length === 0) {
      acc.push(cur);
    }
    return acc;
  }, []);

  if (or) {
    return newRequiredPermissions.length !== requiredPermissions.length;
  }

  return newRequiredPermissions.length === 0;
};
