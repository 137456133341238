import React from 'react';

import { AuthProvider } from './auth';
import { I18nFormattersProvider } from './i18nFormatters';
import { UserProvider } from './user';
import { ScrollProvider } from './scroll';
import { GroupProvider } from './group';
import { ThemeProvider } from './theme';

const AppProvider = ({ children }) => (
  <ThemeProvider>
    <I18nFormattersProvider>
      <AuthProvider>
        <GroupProvider>
          <UserProvider>
            <ScrollProvider>{children}</ScrollProvider>
          </UserProvider>
        </GroupProvider>
      </AuthProvider>
    </I18nFormattersProvider>
  </ThemeProvider>
);

export default AppProvider;
