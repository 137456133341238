import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { Button, PageTitle } from '@combateafraude/react';

import PermissionWrapper from 'routes/PermissionWrapper';

import { useAuth } from 'hooks/auth';

import useDynamoComponentList from 'components/List/hooks/useDynamoComponentList';

import androidLogo from 'assets/images/android-logo.png';
import iosLogo from 'assets/images/ios-logo.png';

import useGenerateAccessKeys from './components/GenerateAccessKey';
import AccessKeysCard from './components/AccessKeysCard';

import Wrapper from '../wrapper';

import './styles.less';

const { Title, Link } = Typography;

const AccessKeys = () => {
  const refreshListRef = useRef(() => {});
  const { t: translate } = useTranslation();

  const { loggedUser } = useAuth();

  const { openModal, GenerateAccessKeyModal } = useGenerateAccessKeys({ refreshListRef });

  const { componentList, refreshList } = useDynamoComponentList({
    component: AccessKeysCard,
    rowKey: 'client_secret',
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/access-keys`,
      config: {
        params: { tenantId: loggedUser?.tenantId },
      },
    },
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <>
      {GenerateAccessKeyModal}

      <Wrapper id="access-keys-component">
        <div className="flex align-center gx-w-100 mrg-btm-40">
          <PageTitle
            title={translate('pages.private.accessKeys.index.pageTitle.title')}
            subtitle={translate('pages.private.accessKeys.index.pageTitle.subtitle')}
          />

          <div className="flex end-center documentation mrg-right-20">
            <Title level={4} className="no-mrg-vertical mrg-right-15">
              {translate('pages.private.accessKeys.index.docs')}
            </Title>
            <Link
              href="https://docs.combateafraude.com/docs/mobile/android/getting-started/"
              target="_blank"
              className="mrg-right-10"
            >
              <img
                src={androidLogo}
                alt={translate('pages.private.accessKeys.index.alts.androidLogo')}
                className="android-logo grayscale-95"
              />
            </Link>
            <Link
              href="https://docs.combateafraude.com/docs/mobile/ios/getting-started/"
              target="_blank"
            >
              <img
                src={iosLogo}
                alt={translate('pages.private.accessKeys.index.alts.iosLogo')}
                className="ios-logo grayscale-95"
              />
            </Link>
          </div>

          <div className="flex center">
            <PermissionWrapper requiredPermissions={['access-keys:create']}>
              <Button type="primary" onClick={openModal}>
                {translate('pages.private.accessKeys.index.newKey')}
              </Button>
            </PermissionWrapper>
          </div>
        </div>

        {componentList}
      </Wrapper>
    </>
  );
};

export default AccessKeys;
