import React from 'react';

import { useAuth } from 'hooks/auth';
import { hasRequiredPermissions } from 'routes/permissionFunctions';

import pdvTenants from 'utils/skyPDVTenants';

import UsersListPDV from './components/UsersListPDV';

const UsersPDV = () => {
  const { loggedUser } = useAuth();

  return (
    <>
      {hasRequiredPermissions(loggedUser, ['users-pdv:read'], []) &&
        pdvTenants?.includes(loggedUser?.tenantId) && <UsersListPDV />}
    </>
  );
};

export default UsersPDV;
