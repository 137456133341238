import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';

import { useScroll } from 'hooks/scroll';
import { useTheme } from 'hooks/theme';

import ManagementMenu from 'components/Management';

import UserInfo from 'components/UserInfo';

import HorizontalNav from './HorizontalNav';

import './styles.less';

const { Header: AntdHeader } = Layout;

const Header = ({ subheader, hideMainHeader }) => {
  const { isAtTop, resetScroll } = useScroll();
  const { logo } = useTheme();

  const mainHeaderClassName = useMemo(
    () =>
      hideMainHeader
        ? `gx-header-horizontal-main ${isAtTop ? 'header-show' : 'header-hide'}`
        : 'gx-header-horizontal-main header-show',
    [hideMainHeader, isAtTop]
  );

  useEffect(() => resetScroll(), [resetScroll]);

  return (
    <div
      id="header-component"
      className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal"
    >
      <AntdHeader className={mainHeaderClassName}>
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            {/* <div className="gx-pointer">
              <NavigationMenu />
            </div> */}

            <Link to="/" className="gx-pointer mrg-right-45">
              <img alt="" src={logo} height="32px" />
            </Link>

            <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-flex flex-1">
              <HorizontalNav />
            </div>

            <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block mrg-right-10">
              <ManagementMenu />
            </div>

            <ul className="gx-header-notifications gx-ml-auto no-pdd-right">
              <li className="gx-user-nav">
                <UserInfo />
              </li>
            </ul>
          </div>
        </div>
      </AntdHeader>

      {subheader}
    </div>
  );
};

Header.propTypes = {
  subheader: PropTypes.node,
  hideMainHeader: PropTypes.bool,
};

Header.defaultProps = {
  subheader: <></>,
  hideMainHeader: false,
};

export default Header;
