export const passwordValidation = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

export const minimunCharacter = /^.{8,}/;

export const uppercaseCharacter = /^(?=.*?[A-Z])/;

export const specialCharacter = /^(?=.*?[#?!@$%^&*-])/;

export const lowercaseCharacter = /^(?=.*?[a-z])/;

export const numberCharacter = /^(?=.*?[0-9])/;

export const renderValidationStatus = (valid) => {
  if (valid) {
    return {
      type: 'success',
      icon: 'caf-ic_checkmark',
    };
  }
  return {
    type: 'error',
    icon: 'caf-ic_close',
  };
};
