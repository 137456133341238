/* eslint-disable react/prop-types */
import React from 'react';

import { useTranslation } from 'react-i18next';
import CustomCollapse from '../CustomCollapse';

import PolicyRow from '../PolicyRow';

const I18N_BASE_PATH =
  'pages.private.accessPolicies.components.policiesSelect.actions.components.managementPanels.consumptionPanels';

const ConsumptionPanel = ({ disabled, formRef }) => {
  const { t: translate } = useTranslation();

  return (
    <CustomCollapse
      ruleName="access-keys"
      disabled={disabled}
      className="no-border-top"
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="ic_dollar_sign"
    >
      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleComsumption`)}
        icon="ic_dollar_sign"
        ruleName="consumption"
        availability={[true, false, false, false]}
      />
    </CustomCollapse>
  );
};

export default ConsumptionPanel;
