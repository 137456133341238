import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@combateafraude/react';
import { Card, DatePicker, Row, Button, Tooltip } from 'antd';
import { getMonth, getYear } from 'date-fns';
import moment from 'moment';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import useConsumptionTable from './hooks/useConsumptionTable';
import StackedProgressBar from './components/StackedProgressBar';

import Wrapper from '../wrapper';

import './styles.less';

const Consumptions = () => {
  const { t: translate } = useTranslation();
  const { i18nNumberToFormattedString } = useI18nFormatters();

  const refreshListRef = useRef(() => {});

  const [totalMonthConsumption, setTotalMonthConsumption] = useState(0);
  const [monthInvoice, setMonthInvoice] = useState(null);
  const [monthItems, setMonthItems] = useState([]);

  const customDataParser = async (res) => {
    const { items, totalMonthCost, invoice } = res?.docs;

    setMonthItems(items || []);
    setTotalMonthConsumption(totalMonthCost || 0);

    setMonthInvoice(invoice);

    return items;
  };

  const generateRows = (record) => (
    <div>
      <span className="product-name">{record?.name}</span>
      <div className="product-data-row">
        <div>
          <span className="product-price">
            {translate('pages.private.consumptions.index.generateRows.unitValue')}{' '}
            {i18nNumberToFormattedString(record?.price, 'pt-BR') ||
              i18nNumberToFormattedString(0, 'pt-BR')}
          </span>
          <span className="total-executions">
            {translate('pages.private.consumptions.index.generateRows.executions')}{' '}
            {record?.totalExecutions || 0}
          </span>
        </div>
        <span className="total-price">
          {translate('pages.private.consumptions.index.generateRows.total')}{' '}
          {i18nNumberToFormattedString(record?.totalPrice, 'pt-BR')}
        </span>
      </div>
    </div>
  );

  const {
    tableContent,
    refreshList,
    updateParams,
    loading,
    loadingCustomData,
  } = useConsumptionTable({
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/consumption`,
      config: {
        params: {
          month: +getMonth(new Date()) + 1,
          year: getYear(new Date()),
        },
      },
    },
    columns: [
      {
        render: (__, record) =>
          (record?.name && generateRows(record)) ||
          `${translate(
            'pages.private.consumptions.index.generateRows.total'
          )} ${i18nNumberToFormattedString(record?.totalMonthCost || 0, 'pt-BR')}`,
      },
    ],
    customDataParser,
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  const handleDateChange = (date, __) => {
    setMonthInvoice(null);
    setMonthItems(null);
    setTotalMonthConsumption(0);

    const selectedMonth = +getMonth(moment(date).toDate()) + 1;
    const selectedYear = +getYear(moment(date).toDate());
    updateParams({
      url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/consumption`,
      config: {
        params: {
          month: selectedMonth,
          year: selectedYear,
        },
      },
    });
  };

  return (
    <Wrapper id="consumptions-component">
      <Row align="middle" justify="space-between">
        <PageTitle
          title={translate('pages.private.consumptions.index.pageTitle.title')}
        />
        <DatePicker
          picker="month"
          defaultValue={moment(new Date())}
          onChange={handleDateChange}
          format="MM/YYYY"
        />
      </Row>

      <div
        className="gx-w-100 mrg-top-10"
        style={{
          display: 'grid',
          gridTemplateColumns: monthInvoice ? '1fr 350px' : '1fr',
        }}
      >
        <div>
          {!loading && !loadingCustomData && (
            <StackedProgressBar
              items={monthItems || []}
              total={totalMonthConsumption || 0}
            />
          )}
          {tableContent}
        </div>
        {monthInvoice && !loading && !loadingCustomData && (
          <Card id="invoice-card">
            <Row className="invoice-title">
              {translate('pages.private.consumptions.index.invoiceInfo')}
            </Row>
            <Row className="invoice-item" align="middle" justify="space-between">
              <p>{translate('pages.private.consumptions.index.totalPaid')}</p>
              <p>{i18nNumberToFormattedString(monthInvoice.totalPaid || 0, 'pt-BR')}</p>
            </Row>
            <Row className="invoice-item" align="middle" justify="space-between">
              <p>
                {translate('pages.private.consumptions.index.others')}{' '}
                <Tooltip
                  title={translate('pages.private.consumptions.index.tooltipText')}
                >
                  <span className="text-link">(?)</span>
                </Tooltip>
              </p>
              <p>{i18nNumberToFormattedString(monthInvoice.taxesPaid || 0, 'pt-BR')}</p>
            </Row>

            <Row className="invoice-item" align="middle" justify="space-between">
              <p>{translate('pages.private.consumptions.index.dueDate')}</p>
              <p>
                {monthInvoice.dueDate
                  ? moment(monthInvoice.dueDate, 'YYYY-MM-DD')
                      .toDate()
                      .toLocaleDateString('pt-BR')
                  : '-'}
              </p>
            </Row>

            <Row className="invoice-item" align="middle" justify="space-between">
              <p>{translate('pages.private.consumptions.index.paidAt')}</p>
              <p>
                {monthInvoice.paidAt
                  ? moment(monthInvoice.paidAt, 'YYYY-MM-DD')
                      .toDate()
                      .toLocaleDateString('pt-BR')
                  : '-'}
              </p>
            </Row>

            <Row align="middle" justify="end">
              <Button
                type="primary"
                shape="round"
                href={monthInvoice.iuguUrl}
                target="blank"
                className="invoice-button"
              >
                {translate('pages.private.consumptions.index.invoiceButton')}
                <i className="caf-ic_arrow-up-right" />
              </Button>
            </Row>
          </Card>
        )}
      </div>
    </Wrapper>
  );
};

export default Consumptions;
