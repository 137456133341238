import React, { createContext, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ptBRAnt from 'antd/es/locale/pt_BR';
import enUSAnt from 'antd/es/locale/en_US';

import { format as formatDateFns } from 'date-fns';
import ptBRDateFns from 'date-fns/locale/pt-BR';
import enUSDateFns from 'date-fns/locale/en-US';
import deDEDateFns from 'date-fns/locale/de';

import validateMessages from 'utils/formValidateMessages';

import { i18nDateFormats, i18nCurrencyFormats } from 'i18n/utils/i18nFormats';

const I18nFormattersContext = createContext({});

const I18nFormattersProvider = ({ children }) => {
  const { i18n } = useTranslation();

  const i18nSetAntFormValidateMessages = useCallback(() => {
    switch (i18n?.language) {
      case 'pt-BR':
        return validateMessages['pt-BR'];

      case 'en-US':
        return validateMessages['en-US'];

      default:
        return validateMessages['pt-BR'];
    }
  }, [i18n]);

  const i18nSetAntLocale = useCallback(() => {
    switch (i18n?.language) {
      case 'pt-BR':
        return ptBRAnt;

      case 'en-US':
        return enUSAnt;

      default:
        return ptBRAnt;
    }
  }, [i18n]);

  const i18nGetDateFnsLocale = useCallback((locale) => {
    switch (locale) {
      case 'pt-BR':
        return ptBRDateFns;

      case 'en-US':
        return enUSDateFns;

      case 'de-DE':
        return deDEDateFns;

      default:
        return ptBRDateFns;
    }
  }, []);

  const i18nFormatDate = useCallback(
    (date, _format, _default) => {
      try {
        const dateFormatted = formatDateFns(
          new Date(date),
          i18nDateFormats[_format][i18n?.language] ||
            i18nDateFormats.default[i18n?.language],
          { locale: i18nGetDateFnsLocale(i18n?.language) }
        );

        return dateFormatted;
      } catch (error) {
        return _default || '-';
      }
    },
    [i18n, i18nGetDateFnsLocale]
  );

  const i18nNumberToFormattedString = useCallback(
    (value, language) => {
      if (!value) return `${i18nCurrencyFormats[language || i18n?.language]} 0,00`;

      return `${i18nCurrencyFormats[language || i18n?.language]} ${value.toLocaleString(
        language || i18n?.language,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}`;
    },
    [i18n]
  );

  return (
    <I18nFormattersContext.Provider
      value={{
        i18nSetAntFormValidateMessages,
        i18nSetAntLocale,
        i18nGetDateFnsLocale,
        i18nFormatDate,
        i18nNumberToFormattedString,
      }}
    >
      {children}
    </I18nFormattersContext.Provider>
  );
};

function useI18nFormatters() {
  const context = useContext(I18nFormattersContext);

  if (!context) {
    throw new Error('useI18nFormatters must be used within an I18nFormattersContext');
  }

  return context;
}

export { I18nFormattersProvider, useI18nFormatters };
