/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { useTranslation } from 'react-i18next';

import CustomCollapse from '../CustomCollapse';
import PolicyRow from '../PolicyRow';

const I18N_BASE_PATH =
  'pages.private.accessPolicies.components.policiesSelect.actions.components.trustPanels.adminPanel';

const AdminPanel = ({ disabled, formRef }) => {
  const { t: translate } = useTranslation();

  return (
    <CustomCollapse
      ruleName="admin"
      disabled={disabled}
      className="no-border-top no-border-bottom"
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="ic_settings"
    >
      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleRestrictiveList`)}
        icon="ic_file"
        ruleName="restrictive-list"
        availability={[true, true, true, true]}
      />

      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleDenyList`)}
        icon="ic_file"
        ruleName="deny-list"
        availability={[true, true, true, true]}
      />

      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleDeveloperVision`)}
        icon="ic_code"
        ruleName="integration-guide"
        availability={[true, false, false, false]}
      />
    </CustomCollapse>
  );
};

export default AdminPanel;
