import axios from 'axios';
import Cookies from 'js-cookie';

const ACCESS_TOKEN = '__Secure-CAF_access_token';
const access_token = Cookies.get(ACCESS_TOKEN);

const api = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL_MANAGEMENT_API,
  headers: {
    Authorization: `Bearer ${access_token}`,
  },
});

export const cleanApi = axios.create();

export default api;
