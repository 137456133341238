import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Modal, Row, Col, Typography, message } from 'antd';
import { PageTitle, Button } from '@combateafraude/react';

import { useAuth } from 'hooks/auth';

import api from 'services/api';

// import LabelDivider from 'components/Divider/LabelDivider';
import RequestSuccess from 'components/RequestResult/Success';
import HiddenParagraph from 'components/HiddenParagraph';

import mobileProducts from 'pages/private/AccessKeys/utils/mobileProducts';

import './styles.less';

const { Title } = Typography;

const I18N_BASE_PATH = 'pages.private.accessKeys.components.generateAccessKey';

const GenerateAccessKey = ({ refreshListRef }) => {
  const [form] = Form.useForm();
  const { t: translate } = useTranslation();

  const { loggedUser } = useAuth();

  const [modalVisible, setModalVisible] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [finishedData, setFinishedData] = useState({});
  const [loadingGenerateAccessKey, setLoadingGenerateAccessKey] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const closeModal = useCallback(() => {
    setModalVisible(false);

    setTimeout(() => {
      form.resetFields();
      setIsFinished(false);
      setDisabledSubmit(true);
    }, []);
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleFormChange = useCallback((values) => {
    if (!values?.products?.length) {
      setDisabledSubmit(true);
    } else {
      setDisabledSubmit(false);
    }
  }, []);

  const onGenerateAccessKey = useCallback(
    async (payload) => {
      try {
        setLoadingGenerateAccessKey(true);

        const { products } = payload;

        const { data: response } = await api.post(
          `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/access-keys`,
          {
            id: loggedUser?.id,
            tenantId: loggedUser?.tenantId,
            products,
          }
        );

        const { data: responseData } = response || {};

        setFinishedData(responseData);
        setIsFinished(true);

        refreshListRef.current();
      } catch (error) {
        message.error(translate(`${I18N_BASE_PATH}.onGenerateAccessKey.messageError`));
      } finally {
        setLoadingGenerateAccessKey(false);
      }
    },
    [loggedUser, refreshListRef, translate]
  );

  const renderCheckboxesList = useCallback(
    () =>
      mobileProducts.map(
        (mp) =>
          mp.enabled && (
            <Col key={mp.name} span={12}>
              <Checkbox value={mp.name}>
                <div className="checkbox-custom-label">
                  <div className="icon">
                    <i className={mp.icon} />
                  </div>
                  <div className="details">
                    <strong>{translate(mp.title)}</strong>
                    <span>{translate(mp.description)}</span>
                  </div>
                </div>
              </Checkbox>
            </Col>
          )
      ),
    [translate]
  );

  const GenerateAccessKeyModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={800}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div id="generate-access-key-component" className="no-mrg no-pdd">
          {isFinished ? (
            <RequestSuccess
              title={translate(`${I18N_BASE_PATH}.requestSuccess.title`)}
              className="mrg-vertical-50"
              footer={
                <Row gutter={[16, 16]} className="gx-w-100 mrg-top-40 mrg-horizon-80">
                  <Col span={10}>
                    <Title level={5} className="mrg-btm-15">
                      {translate(`${I18N_BASE_PATH}.requestSuccess.footer.clientId`)}
                    </Title>
                    <subtitle>{finishedData?._id}</subtitle>
                  </Col>
                  <Col span={14}>
                    <Title level={5}>
                      {translate(`${I18N_BASE_PATH}.requestSuccess.footer.clientSecret`)}
                    </Title>
                    <HiddenParagraph text={finishedData?.secret} hidden />
                  </Col>
                </Row>
              }
            />
          ) : (
            <>
              <PageTitle
                title={translate(`${I18N_BASE_PATH}.pageTitle.title`)}
                subtitle={translate(`${I18N_BASE_PATH}.pageTitle.subtitle`)}
              />

              <Form
                form={form}
                layout="vertical"
                className="mrg-top-40"
                onValuesChange={handleFormChange}
                onFinish={onGenerateAccessKey}
              >
                {/* <LabelDivider title="API Combate a Fraude" /> */}

                <Form.Item name="products" className="no-mrg">
                  <Checkbox.Group className="checkbox-group">
                    <Row gutter={[24, 24]}>{renderCheckboxesList()}</Row>
                  </Checkbox.Group>
                </Form.Item>

                <div className="flex end-center mrg-top-40">
                  <Button
                    type="default"
                    onClick={closeModal}
                    className="btn-cancel"
                    disabled={loadingGenerateAccessKey}
                  >
                    {translate(`${I18N_BASE_PATH}.buttons.cancel`)}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={disabledSubmit || loadingGenerateAccessKey}
                    loading={loadingGenerateAccessKey}
                  >
                    {loadingGenerateAccessKey
                      ? translate(`${I18N_BASE_PATH}.buttons.okLoading`)
                      : translate(`${I18N_BASE_PATH}.buttons.okText`)}
                  </Button>
                </div>
              </Form>
            </>
          )}
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      form,
      isFinished,
      finishedData,
      renderCheckboxesList,
      handleFormChange,
      onGenerateAccessKey,
      loadingGenerateAccessKey,
      disabledSubmit,
      translate,
    ]
  );

  return { openModal, closeModal, GenerateAccessKeyModal };
};

export default GenerateAccessKey;
