import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { Button } from '@combateafraude/react';

import { useAuth } from 'hooks/auth';

import notify from 'assets/images/notify.svg';

import Wrapper from '../wrapper';

import './styles.less';

const { Title, Paragraph } = Typography;

const Unauthorized = () => {
  const { t: translate } = useTranslation();
  const { loggedUser } = useAuth();

  const hasProduct = useMemo(() => !!loggedUser?.products?.management, [loggedUser]);

  return (
    <Wrapper id="unauthorized-component">
      <div id="unauthorized-content" className="flex-column center">
        <Title className="title">
          {translate('pages.private.unauthorized.index.wrapperTitle')}{' '}
          {hasProduct
            ? translate('pages.private.unauthorized.index.thisPage')
            : translate('pages.private.unauthorized.index.thisSystem')}
        </Title>

        <Paragraph className="subtitle">
          {translate('pages.private.unauthorized.index.wrapperParagraph')}
        </Paragraph>

        <img
          src={notify}
          alt={translate('pages.private.unauthorized.index.wrapperImg.alt')}
          className="grayscale-95 mrg-top-60 mrg-btm-30"
        />

        {hasProduct && (
          <Button type="primary">
            <Link to="/dashboard">
              {translate('pages.private.unauthorized.index.wrapperButton')}
            </Link>
          </Button>
        )}
      </div>
    </Wrapper>
  );
};

export default Unauthorized;
