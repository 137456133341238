import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/auth';
import Unauthorized from 'pages/private/Unauthorized';

import Wrapper from '../wrapper';
import './styles.less';

const I18N_BASE_PATH = 'pages.private.onboardingBuilder';

const OnboardingBuilder = () => {
  const { t: translate } = useTranslation();
  const { loggedUser } = useAuth();
  const isCafIo = window.location.origin.indexOf('caf.io') !== -1;
  useEffect(() => {
    const onboardingList = document.getElementById('onboarding-templates-list');
    if (onboardingList) onboardingList.classList.add('style-iframe');
  }, []);
  return loggedUser?.accountData?.featureFlags?.useOnboardingBuilder ? (
    <Wrapper
      style={{ width: '100%' }}
      customClassName="no-pdd"
      id="onboarding-builder-iframe"
    >
      <iframe
        title={translate(`${I18N_BASE_PATH}.templates`)}
        src={`${
          isCafIo
            ? process.env.REACT_APP_ONBOARDING_BUILDER_URL_NEW
            : process.env.REACT_APP_ONBOARDING_BUILDER_URL
        }onboarding?tenantId=${loggedUser?.tenantId}&newTab`}
        allow="clipboard-write"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    </Wrapper>
  ) : (
    <Unauthorized />
  );
};

export default OnboardingBuilder;
