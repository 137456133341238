import React, { Suspense } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import 'assets/vendors/style';
import 'styles/wieldy.less';
import 'assets/css/caf-icons.css';

import AppProvider from 'hooks';

import HttpInterceptor from 'utils/httpInterceptor';

import Routes from './routes';

const App = () => (
  <Suspense fallback={<></>}>
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <AppProvider>
          <Routes />
          <HttpInterceptor />
        </AppProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </Suspense>
);

export default App;
