/* eslint-disable prefer-promise-reject-errors */
import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Collapse, Avatar, Input, Form, Row, Col, Upload, message } from 'antd';
import { Button, PageTitle } from '@combateafraude/react';

import ProfileDefault from 'assets/images/profile-default.png';

import PasswordValidations from 'components/PasswordValidations';
import LanguageSelector from 'components/I18n/LanguageSelector';

import { useAuth } from 'hooks/auth';

import { useFetch } from 'services/hooks';
import Cookies from 'js-cookie';

import {
  passwordValidation,
  minimunCharacter,
  uppercaseCharacter,
  specialCharacter,
  lowercaseCharacter,
  numberCharacter,
} from 'utils/validations';

import './styles.less';

const { Panel } = Collapse;
const ACCEPT_FILES = ['image/jpeg', 'image/jpg', 'image/png'];
const isLocalhost = !!window.location.origin.startsWith('http://localhost');

const LANGUAGE = isLocalhost ? 'CAF_language' : '__Secure-CAF_language';

const COOKIE_OPTIONS = isLocalhost
  ? {
      path: '/',
    }
  : {
      domain: '.combateafraude.com',
      secure: true,
      sameSite: 'strict',
    };

const ModalUpdateProfile = () => {
  const { put, loading } = useFetch();
  const { i18n, t: translate } = useTranslation();
  const { loggedUser, getLoggedUser } = useAuth();
  const [form] = Form.useForm();

  const firstLoad = useRef(true);

  const [modalVisible, setModalVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [passwordValue, setPasswordValue] = useState('');

  const closeModal = useCallback(() => {
    setFile(null);
    setModalVisible(false);
    form.setFieldsValue({
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });
    setPasswordValue('');
    firstLoad.current = true;
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleUpdate = useCallback(
    async (payload) => {
      try {
        await put({
          url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users/${loggedUser?.id}`,
          payload: {
            name: payload?.name,
            oldPassword: payload?.oldPassword,
            password: payload?.newPassword,
            language: payload?.language,
          },
        });
        message.success(
          translate('components.modalUpdateProfile.handleUpdate.messages.success')
        );
        closeModal();
        if (payload?.language !== Cookies.get(LANGUAGE)) {
          Cookies.set(LANGUAGE, payload?.language, COOKIE_OPTIONS);
          window.location.reload();
        }
        getLoggedUser();
      } catch {
        message.error(
          translate('components.modalUpdateProfile.handleUpdate.messages.error')
        );
      }
    },
    [closeModal, getLoggedUser, put, loggedUser, translate]
  );

  const uploadImageOnDrop = useCallback(
    async (f, fType) => {
      if (f) {
        try {
          const base64Img = f.split(';base64,', 2);
          await put({
            url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users/${loggedUser.id}`,
            payload: {
              image: { mime: fType, file: base64Img[1] },
            },
          });
          message.success(
            translate('components.modalUpdateProfile.uploadImageOnDrop.messages.success')
          );
          getLoggedUser();
        } catch {
          message.error(
            translate('components.modalUpdateProfile.uploadImageOnDrop.messages.error')
          );
        }
      }
    },
    [getLoggedUser, put, loggedUser, translate]
  );

  const toBase64 = (f) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onChangePassword = useCallback((e) => {
    firstLoad.current = false;
    setPasswordValue(e.target.value);
  }, []);

  const validations = useMemo(
    () => ({
      minimun: minimunCharacter.test(passwordValue),
      uppercase: uppercaseCharacter.test(passwordValue),
      special: specialCharacter.test(passwordValue),
      lowercase: lowercaseCharacter.test(passwordValue),
      number: numberCharacter.test(passwordValue),
    }),
    [passwordValue]
  );

  const UpdateProfileModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={700}
        onCancel={closeModal}
      >
        <div id="modal-profile-update">
          <div>
            <PageTitle
              title={translate(
                'components.modalUpdateProfile.updateProfileModal.pageTitle.title'
              )}
              // subtitle="Altere informações da sua conta."
            />
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleUpdate}
            initialValues={{ name: loggedUser?.name, language: i18n?.language }}
          >
            <Row className="mrg-vertical-45">
              <Col span={8} className="col-avatar">
                <Upload
                  showUploadList={false}
                  onRemove={() => {
                    setFile(null);
                  }}
                  beforeUpload={async (f) => {
                    if (f?.size / 1024 / 1024 > 2) {
                      message.error(
                        translate(
                          'components.modalUpdateProfile.updateProfileModal.upload.messages.veryLargeFile'
                        )
                      );
                      return false;
                    }

                    if (!ACCEPT_FILES.includes(f?.type)) {
                      message.error(
                        translate(
                          'components.modalUpdateProfile.updateProfileModal.upload.messages.fileTypeNotAllowed'
                        )
                      );
                      return false;
                    }

                    const result = await toBase64(f).catch((e) => Error(e));
                    setFile(result);
                    uploadImageOnDrop(result, f?.type);
                    return false;
                  }}
                  accept=".png, .jpg, .jpeg"
                >
                  <div className="update-profile-pointer">
                    <Avatar
                      src={file || loggedUser?.profileImageUrl || ProfileDefault}
                      size={120}
                      className={
                        !file && !loggedUser?.profileImageUrl ? 'grayscale-95' : ''
                      }
                    />
                    <i className="caf-ic_camera gtx-text-gray" />
                  </div>
                </Upload>
                <div className="font-size-11 mrg-top-20 flex center">
                  <span>
                    {translate(
                      'components.modalUpdateProfile.updateProfileModal.maximumSize'
                    )}
                  </span>
                </div>
              </Col>
              <Col span={16}>
                <Form.Item
                  label={translate(
                    'components.modalUpdateProfile.updateProfileModal.form.name.label'
                  )}
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input defaultValue={loggedUser?.name} />
                </Form.Item>

                <Form.Item
                  label={translate(
                    'components.modalUpdateProfile.updateProfileModal.form.email.label'
                  )}
                >
                  <Input disabled value={loggedUser?.username} />
                </Form.Item>

                {process.env.REACT_APP_ENV !== 'prod' && <LanguageSelector />}

                <Collapse ghost>
                  <Panel
                    header={translate(
                      'components.modalUpdateProfile.updateProfileModal.form.collapse.panel.header'
                    )}
                    key="1"
                  >
                    <Form.Item
                      label={translate(
                        'components.modalUpdateProfile.updateProfileModal.form.collapse.panel.oldPassword.label'
                      )}
                      name="oldPassword"
                      rules={[
                        // { required: true }
                        ({ getFieldValue }) => ({
                          validator(rule, oldPassword) {
                            const newPassword = getFieldValue('newPassword');
                            if (newPassword && !oldPassword) {
                              return Promise.reject('Obrigatório');
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        disabled={loading}
                        size="large"
                        prefix={<i className="caf-lock" />}
                        value={passwordValue}
                        autoComplete="old-password"
                      />
                    </Form.Item>

                    <Form.Item
                      label={translate(
                        'components.modalUpdateProfile.updateProfileModal.form.collapse.panel.newPassword.label'
                      )}
                      name="newPassword"
                      rules={[
                        // { required: true },
                        ({ getFieldValue }) => ({
                          validator(rule, newPassword) {
                            const oldPassword = getFieldValue('oldPassword');
                            if (oldPassword && !newPassword) {
                              return Promise.reject(
                                translate(
                                  'components.modalUpdateProfile.updateProfileModal.form.collapse.panel.newPassword.rules.messages.required'
                                )
                              );
                            }

                            if (!newPassword || passwordValidation.test(newPassword)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              translate(
                                'components.modalUpdateProfile.updateProfileModal.form.collapse.panel.newPassword.rules.messages.requiredRequirements'
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        disabled={loading}
                        size="large"
                        prefix={<i className="caf-lock" />}
                        onChange={onChangePassword}
                        value={passwordValue}
                        autoComplete="new-password"
                      />
                    </Form.Item>
                    <Form.Item
                      label={translate(
                        'components.modalUpdateProfile.updateProfileModal.form.collapse.panel.confirmNewPassword.label'
                      )}
                      name="confirmNewPassword"
                      rules={[
                        // { required: true },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (getFieldValue('newPassword') !== value) {
                              return Promise.reject(
                                new Error(
                                  translate(
                                    'components.modalUpdateProfile.updateProfileModal.form.collapse.panel.confirmNewPassword.rules.messages.passwordValidator'
                                  )
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        disabled={loading}
                        size="large"
                        prefix={<i className="caf-lock" />}
                        autoComplete="new-password"
                      />
                    </Form.Item>
                    <PasswordValidations
                      validations={validations}
                      firstLoad={firstLoad.current}
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Row>

            <div className="update-profile-buttons flex end-center">
              {/* <Button onClick={openChangePasswordModal} className="btn-change-password">
               {translate(
                  'components.modalUpdateProfile.updateProfileModal.form.button.changePassword'
                )}
              </Button> */}
              <div>
                <Button type="default" onClick={closeModal} className="btn-cancel">
                  {translate(
                    'components.modalUpdateProfile.updateProfileModal.form.button.cancel'
                  )}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  {translate(
                    'components.modalUpdateProfile.updateProfileModal.form.button.save'
                  )}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      form,
      handleUpdate,
      loggedUser,
      file,
      loading,
      passwordValue,
      onChangePassword,
      validations,
      uploadImageOnDrop,
      translate,
      i18n,
    ]
  );

  return { openModal, UpdateProfileModal };
};

export default ModalUpdateProfile;
