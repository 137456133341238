import React, { useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useHistory } from 'react-router-dom';
import { Button, PageTitle } from '@combateafraude/react';
import { Form, Select } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import _ from 'lodash';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import PermissionWrapper from 'routes/PermissionWrapper';

import SearchInput from 'components/Form/SearchInput';
import useDynamoTable from 'components/List/hooks/useDynamoTable';

import ActionsList from '../PoliciesActions/PoliciesActionsList';

import Wrapper from '../wrapper';

const { Option } = Select;

const products = {
  trust: {
    title: 'Trust',
  },
  management: {
    title: 'Management',
  },
};
// import { Container } from './styles';

const UsersList = () => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const refreshListRef = useRef(() => {});
  const history = useHistory();
  const [query] = useQueryParams({
    search: StringParam,
    product: StringParam,
  });

  const columns = [
    {
      key: 'name',
      title: (
        <span className="column-title">
          {translate('pages.private.users.components.policiesList.index.columns.name')}
        </span>
      ),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (__, record) => (
        <Link
          to={`/users-permissions/access-policies/${record?.id}`}
          className="flex align-center text-dark"
        >
          <i className={`${record?.icon} mrg-right-10`} />
          {record?.name}
        </Link>
      ),
    },
    {
      key: 'product',
      title: (
        <span className="column-title">
          {translate('pages.private.users.components.policiesList.index.columns.product')}
        </span>
      ),
      dataIndex: 'product',
      render: (__, record) => <div>{products[record?.product].title}</div>,
    },
    {
      key: 'updatedAt',
      title: (
        <span className="column-title">
          {translate(
            'pages.private.users.components.policiesList.index.columns.updatedAt'
          )}
        </span>
      ),
      dataIndex: 'updatedAt',
      sorter: (a, b) => {
        const aDate = new Date(a.updatedAt || a.createdAt).getTime();
        const bDate = new Date(b.updatedAt || b.createdAt).getTime();

        return aDate - bDate;
      },
      render: (__, record) => i18nFormatDate(record?.updatedAt || record?.createdAt, 6),
    },
    // {
    //   key: 'products',
    //   title: <span className="column-title">Acesso aos sistemas</span>,
    //   dataIndex: 'products',
    //   render: (__, record) =>
    //     <ProductsAccess products={record?.products} />
    // },
    {
      key: 'actions',
      render: (__, record) => (
        <ActionsList policy={record} refreshListRef={refreshListRef} />
      ),
      width: 60,
    },
  ];

  const { tableContent, refreshList, setFilterData } = useDynamoTable({
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/policy-groups`,
      config: {
        params: {
          // limit: 2,
        },
      },
    },
    queryParams: {
      search: StringParam,
      product: StringParam,
    },
    columns,
    rowKey: 'id',
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        search: values._search ? values._search.trim() : undefined,
        product: values.product || undefined,
      });
    }, 500),
    []
  );

  return (
    <Wrapper>
      <div className="flex align-center gx-w-100 space-between">
        <PageTitle
          title={translate(
            'pages.private.users.components.policiesList.index.pageTitle.title'
          )}
          subtitle={translate(
            'pages.private.users.components.policiesList.index.pageTitle.subtitle'
          )}
        />
        <div className="flex row mrg-top-15 mrg-btm-30 align-center">
          <Form
            layout="horizontal"
            className="flex align-center"
            onValuesChange={handleFormChange}
            initialValues={{ search: query?.search, product: query?.product }}
          >
            <SearchInput
              placeholder={translate(
                'pages.private.users.components.policiesList.index.searchInput'
              )}
            />
            <Form.Item name="product" className="no-mrg-btm mrg-left-15 mrg-right-5">
              <Select
                defaultValue=""
                bordered={false}
                style={{ width: 170, fontWeight: 500 }}
                className="text-dark"
              >
                <Option value="">
                  {translate(
                    'pages.private.users.components.policiesList.index.selectOptions.all'
                  )}
                </Option>
                <Option value="management">
                  {translate(
                    'pages.private.users.components.policiesList.index.selectOptions.management'
                  )}
                </Option>
                <Option value="trust">
                  {translate(
                    'pages.private.users.components.policiesList.index.selectOptions.trust'
                  )}
                </Option>
              </Select>
            </Form.Item>
          </Form>
          <PermissionWrapper requiredPermissions={['permissions:create']}>
            <div className="mrg-left-15">
              <Button
                type="primary"
                className="flex center"
                onClick={() => history.push('/users-permissions/access-policies/create')}
              >
                <i className="caf-ic_plus font-size-15 mrg-right-5" />
                <span>
                  {translate(
                    'pages.private.users.components.policiesList.index.newPolicy'
                  )}
                </span>
              </Button>
            </div>
          </PermissionWrapper>
        </div>
      </div>
      <div className="gx-w-100 mrg-top-10">{tableContent}</div>
    </Wrapper>
  );
};

export default UsersList;
