/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Avatar, Form, Modal, Input, message, Select } from 'antd';
import { PageTitle, Button } from '@combateafraude/react';

import ProfileDefault from 'assets/images/profile-default.png';
import InputMask from 'components/Form/InputMask';

import { useAuth } from 'hooks/auth';
import { useGroup } from 'hooks/group';

import { useFetch } from 'services/hooks';

import {
  passwordValidation,
  minimunCharacter,
  uppercaseCharacter,
  specialCharacter,
  lowercaseCharacter,
  numberCharacter,
} from 'utils/validations';

import PasswordValidations from 'components/PasswordValidations';

import './styles.less';

const { Option } = Select;

const CreateUsers = ({ refreshListRef, type = 'normal' }) => {
  const { t: translate } = useTranslation();
  const [form] = Form.useForm();
  const firstLoad = useRef(true);
  const { listGroups, getListGroups } = useGroup();
  const { post, loading: loadingCreateUser } = useFetch();

  const { loggedUser } = useAuth();

  const [modalVisible, setModalVisible] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');

  const closeModal = useCallback(() => {
    setModalVisible(false);

    setTimeout(() => {
      form.resetFields();
      firstLoad.current = true;
      setPasswordValue('');
    }, 500);
  }, [form]);

  const openModal = useCallback(async () => {
    setModalVisible(true);
  }, []);

  const onChangePassword = useCallback((e) => {
    firstLoad.current = false;
    setPasswordValue(e.target.value);
  }, []);

  const customLabel = useMemo(
    () => (
      <div className="custom-label-content">
        <span>
          {translate('pages.private.users.components.usersActions.createUsers.group')}
        </span>
      </div>
    ),
    [translate]
  );

  const validations = useMemo(
    () => ({
      minimun: minimunCharacter.test(passwordValue),
      uppercase: uppercaseCharacter.test(passwordValue),
      special: specialCharacter.test(passwordValue),
      lowercase: lowercaseCharacter.test(passwordValue),
      number: numberCharacter.test(passwordValue),
    }),
    [passwordValue]
  );

  const allValidationsSatisfy = useMemo(
    () =>
      validations.minimun &&
      validations.uppercase &&
      validations.special &&
      validations.lowercase &&
      validations.number,
    [validations]
  );

  const onCreateUser = useCallback(
    async (payload) => {
      try {
        if (type === 'normal') {
          const { username, password, name, group: usersGroupId } = payload;

          await post({
            url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users`,
            payload: {
              username,
              password,
              name,
              usersGroupId: usersGroupId || null,
              forceChangePassword: true,
              tenantId: loggedUser?.tenantId,
            },
          });
        } else if (type === 'pdv') {
          const { username: email, password, name, cnpj } = payload;

          await post({
            url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/caf/users`,
            payload: {
              email,
              password,
              name,
              cnpj,
              forceChangePassword: true,
              tenantId: loggedUser?.tenantId,
            },
          });
        }
        refreshListRef.current();
        closeModal();
        message.success(
          translate(
            'pages.private.users.components.usersActions.createUsers.onCreateUser.messageSuccess'
          )
        );
      } catch (err) {
        if (err.response.status === 409) {
          message.error(err.response.data.message);
        } else {
          message.error(
            translate(
              'pages.private.users.components.usersActions.createUsers.onCreateUser.messageError'
            )
          );
        }
      }
    },
    [loggedUser, refreshListRef, closeModal, type, post, translate]
  );

  useEffect(() => {
    if (type === 'normal') getListGroups();
  }, [getListGroups, type]);

  const CreateUsersModal = useMemo(
    () => (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        width={700}
      >
        <div className="no-mrg no-pdd" id="create-users-modal">
          <PageTitle
            title={translate(
              'pages.private.users.components.usersActions.createUsers.pageTitle.title'
            )}
            subtitle={translate(
              'pages.private.users.components.usersActions.createUsers.pageTitle.subtitle'
            )}
          />

          <Form
            form={form}
            layout="vertical"
            validateTrigger="finish"
            className="mrg-top-20"
            onFinish={onCreateUser}
          >
            <Row className="mrg-vertical-45">
              <Col span={8} className="flex center-start">
                <Avatar src={ProfileDefault} size={120} className="grayscale-95" />
              </Col>
              <Col span={16}>
                <Form.Item
                  name="name"
                  label={translate(
                    'pages.private.users.components.usersActions.createUsers.name'
                  )}
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={translate(
                      'pages.private.users.components.usersActions.createUsers.name'
                    )}
                    prefix={<i className="caf-ic_person" />}
                  />
                </Form.Item>

                {type === 'pdv' && (
                  <Form.Item
                    name="cnpj"
                    label={translate(
                      'pages.private.users.components.usersActions.createUsers.cnpj'
                    )}
                    rules={[{ required: true }]}
                  >
                    <InputMask
                      autoComplete="new-cnpj"
                      mask="99.999.999/9999-99"
                      className="text-dark"
                      prefix={<i className="caf-ic_company" />}
                      placeholder={translate(
                        'pages.private.users.components.usersActions.createUsers.cnpj'
                      )}
                      allowClear
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="username"
                  label={translate(
                    'pages.private.users.components.usersActions.createUsers.email'
                  )}
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: translate(
                        'pages.private.users.components.usersActions.createUsers.emailMessage'
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={translate(
                      'pages.private.users.components.usersActions.createUsers.email'
                    )}
                    prefix={<i className="caf-ic_mail" />}
                  />
                </Form.Item>

                {type === 'normal' && (
                  <Form.Item
                    name="group"
                    label={customLabel}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      prefix={<i className="caf-ic_users" />}
                      placeholder={translate(
                        'pages.private.users.components.usersActions.createUsers.group'
                      )}
                    >
                      {listGroups?.map((group) => (
                        <Option value={group?.id}>{group?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item
                  name="password"
                  label={
                    type === 'normal'
                      ? translate(
                          'pages.private.users.components.usersActions.createUsers.temporaryPassword'
                        )
                      : translate(
                          'pages.private.users.components.usersActions.createUsers.password'
                        )
                  }
                  rules={[
                    ({ getFieldValue }) => ({
                      validator() {
                        if (passwordValidation.test(getFieldValue('password'))) {
                          return Promise.resolve();
                        }
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject(
                          translate(
                            'pages.private.users.components.usersActions.createUsers.messagePassword'
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder={
                      type === 'normal'
                        ? translate(
                            'pages.private.users.components.usersActions.createUsers.temporaryPassword'
                          )
                        : translate(
                            'pages.private.users.components.usersActions.createUsers.password'
                          )
                    }
                    prefix={<i className="caf-lock" />}
                    value={passwordValue}
                    onChange={onChangePassword}
                    autoComplete="new-password"
                  />
                </Form.Item>

                <PasswordValidations
                  validations={validations}
                  firstLoad={firstLoad.current}
                />
              </Col>
            </Row>

            <div className="flex end-center mrg-top-40">
              <Button
                type="default"
                onClick={closeModal}
                className="btn-cancel"
                disabled={loadingCreateUser}
              >
                {translate(
                  'pages.private.users.components.usersActions.createUsers.buttons.cancel'
                )}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loadingCreateUser || !allValidationsSatisfy}
                loading={loadingCreateUser}
              >
                {loadingCreateUser
                  ? translate(
                      'pages.private.users.components.usersActions.createUsers.buttons.loading'
                    )
                  : translate(
                      'pages.private.users.components.usersActions.createUsers.buttons.okText'
                    )}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      form,
      modalVisible,
      closeModal,
      onCreateUser,
      type,
      listGroups,
      passwordValue,
      onChangePassword,
      validations,
      loadingCreateUser,
      allValidationsSatisfy,
      translate,
      customLabel,
    ]
  );

  return { openModal, closeModal, CreateUsersModal };
};

export default CreateUsers;
