import React from 'react';

// import './styles.less';

const IconSuccess = () => (
  <div id="request-success-icon">
    <i className="caf-ic_checkmark" />
  </div>
);

export default IconSuccess;
