const toCurrency = (value, prefix, language) => {
  if (!value && value !== 0) return '';

  const formatted = parseFloat(value).toLocaleString(language, {
    minimumFractionDigits: 3,
  });

  return prefix ? `${prefix} ${formatted}` : formatted;
};

const toNumber = (value) => {
  if (!value && value !== 0) return null;

  let formatted = `${value}`.replace(/R\$|[,|.]+/g, '').replace(/([0-9]{3})$/g, '.$1');
  formatted = parseFloat(formatted);

  if (`${value}`.length === 1) {
    formatted /= 100;
  }

  return formatted;
};

const numberToFormattedString = (value, prefix, language) => {
  if (!value) return `${prefix} 0,00`;
  return `${prefix} ${value.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const toCurrencyTwoDigits = (value, prefix, language) => {
  if (!value && value !== 0) return '';

  const formatted = parseFloat(value).toLocaleString(language, {
    minimumFractionDigits: 2,
  });

  return prefix ? `${prefix} ${formatted}` : formatted;
};

export { toCurrency, toNumber, numberToFormattedString, toCurrencyTwoDigits };
