/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useAuth } from 'hooks/auth';

import AdminPanel from './AdminPanel';
import MetricsPanel from './MetricsPanel';
import ProfileTemplatesPanel from './ProfileTemplatesPanel';
import QueryPanel from './QueryPanel';
import RoutinesPanel from './RoutinesPanel';
import SpecialPanel from './SpecialPanel';

const TrustPanels = ({ formRef, disabled }) => {
  const { loggedUser } = useAuth();

  // esconde as colunas relacionadas a perfis caso nao exista a flag
  const hideProfileRelatedRows = useMemo(
    () => !loggedUser?.accountData?.featureFlags?.profilesModule,
    [loggedUser]
  );

  return (
    <>
      <MetricsPanel
        formRef={formRef}
        disabled={disabled}
        hideProfileRelatedRows={hideProfileRelatedRows}
      />
      <QueryPanel
        formRef={formRef}
        disabled={disabled}
        hideProfileRelatedRows={hideProfileRelatedRows}
      />
      <ProfileTemplatesPanel
        formRef={formRef}
        disabled={disabled}
        hideProfileRelatedRows={hideProfileRelatedRows}
      />
      <RoutinesPanel
        formRef={formRef}
        disabled={disabled}
        hideProfileRelatedRows={hideProfileRelatedRows}
      />
      <AdminPanel
        formRef={formRef}
        disabled={disabled}
        hideProfileRelatedRows={hideProfileRelatedRows}
      />
      <SpecialPanel
        formRef={formRef}
        disabled={disabled}
        hideProfileRelatedRows={hideProfileRelatedRows}
      />
    </>
  );
};

export default TrustPanels;
