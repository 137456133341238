import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Spin } from 'antd';

import './styles.less';

const CAFLoader = () => {
  const iconLoader = <LoadingOutlined style={{ fontSize: 80 }} spin />;

  return (
    <Row className="flex center full-height">
      <Spin indicator={iconLoader} />
    </Row>
  );
};

export default CAFLoader;
