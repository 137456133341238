/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { useTranslation } from 'react-i18next';
import CustomCollapse from '../CustomCollapse';
import PolicyRow from '../PolicyRow';

const I18N_BASE_PATH =
  'pages.private.accessPolicies.components.policiesSelect.actions.components.trustPanels.consultPanel';
const RULE_NAME = 'query';

const QueryPanel = ({ disabled, formRef, hideProfileRelatedRows }) => {
  const { t: translate } = useTranslation();

  return (
    <CustomCollapse
      ruleName={RULE_NAME}
      disabled={disabled}
      className="no-border-top no-border-bottom"
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="ic_search"
    >
      {!hideProfileRelatedRows && (
        <>
          <PolicyRow
            formRef={formRef}
            disabled={disabled}
            title={translate(`${I18N_BASE_PATH}.policyRows.titleIndividuals`)}
            icon="ic_person"
            ruleName="people"
            availability={[true, true, true, false]}
          />

          <PolicyRow
            formRef={formRef}
            disabled={disabled}
            title={translate(`${I18N_BASE_PATH}.policyRows.titleCompanyEntities`)}
            icon="ic_company"
            ruleName="companies"
            availability={[true, true, true, false]}
          />
        </>
      )}
      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleQueries`)}
        icon="ic_file"
        ruleName="executions"
        availability={[true, true, true, false]}
      />
      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleFaceliveness`)}
        icon="ic_face"
        ruleName="faceliveness"
        availability={[true, false, false, false]}
      />
      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleFaceauthenticator`)}
        icon="ic_face"
        ruleName="face-authenticator"
        availability={[true, false, false, false]}
      />
    </CustomCollapse>
  );
};

export default QueryPanel;
