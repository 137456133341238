/* eslint-disable react/prop-types */
import React from 'react';

import ManagementPanels from './components/ManagementPanels';
import TrustPanels from './components/TrustPanels';

import './styles.less';

const PoliciesSelect = ({ formRef, disabled, selectedProduct }) => (
  <div id="select-policies">
    {selectedProduct === 'trust' && <TrustPanels formRef={formRef} disabled={disabled} />}

    {selectedProduct === 'management' && (
      <ManagementPanels formRef={formRef} disabled={disabled} />
    )}
  </div>
);
export default PoliciesSelect;
