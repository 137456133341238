import { format as formatDateFns } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import enUS from 'date-fns/locale/en-US';

export const toFormatDate = (date, language, _format, _default) => {
  try {
    if (!date || date.includes('0001-01-01')) return _default || '-';

    const defaultDateToFormat = `dd/MM/yyyy 'às' HH:mm`;

    return formatDateFns(new Date(date), _format || defaultDateToFormat, {
      locale: language === 'pt-BR' ? ptBR : enUS,
    });
  } catch (error) {
    return _default || '-';
  }
};

export const toMaskedCpf = (value) => {
  if (!value) return '';
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};

export const toMaskedCnpj = (value) => {
  if (!value) return '';
  return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
};

export const toMaskedCep = (value) => {
  if (!value) return '';
  return value.replace(/(\d{5})(\d{3})/, '$1-$2');
};

export const secondsToDay = (seconds) => {
  const parsedSeconds = parseInt(seconds, 10);
  const formatted = parsedSeconds / 86400;

  return formatted;
};
