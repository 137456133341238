import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/auth';

const CustomerCustomizationWrapper = ({ customer, extraCondition, children }) => {
  const { loggedUser } = useAuth();

  const showContent = useMemo(() => {
    const userTenantId = loggedUser?.tenantId;

    if (
      [
        '7324cdd9-4c3d-4557-8cb6-650b1893fd73',
        'fb3d6bd4-7892-436a-b9b1-9f284389995e', // conta de teste (dev)
        'e8a13640-40be-4ac3-91a4-bbbc488dff61', // nova conta de teste (dev)
        'ed7d5fa4-776d-4240-b1d2-8219ed9b455d', // conta de teste (beta)
        'f8996922-8f20-4a6e-a176-f282bd3b6f3c', // conta de teste (prod)
      ].includes(userTenantId)
    ) {
      return true;
    }

    switch (customer?.toUpperCase()) {
      case 'SKY':
        return ['c94b990c-1eab-40ea-bbdf-684635bc5af2'].includes(userTenantId);
      case 'ZOOP':
        return [
          '999687e1-ccfc-46e9-bb4e-03f869456ec7',
          'f9514663-5bfa-4f99-8b43-22a99ef4d5db',
        ].includes(userTenantId);
      default:
        return false;
    }
  }, [loggedUser, customer]);

  return extraCondition && showContent ? children : <></>;
};

CustomerCustomizationWrapper.propTypes = {
  customer: PropTypes.string.isRequired,
  extraCondition: PropTypes.bool,
};

CustomerCustomizationWrapper.defaultProps = {
  extraCondition: true,
};

export default CustomerCustomizationWrapper;
