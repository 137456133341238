import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const SearchInput = ({ placeholder, icon, name, disabled, className, ...rest }) => {
  const { t: translate } = useTranslation();
  return (
    <Form.Item
      {...rest}
      name={name}
      className={`reset-vertical-mrg reset-horizon-mrg ${className}`}
    >
      <Input
        disabled={disabled}
        className="custom-search-input text-dark font-size-15"
        autoComplete="off"
        placeholder={placeholder || translate('components.form.searchInput.placeholder')}
        prefix={<i className={icon} />}
      />
    </Form.Item>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

SearchInput.defaultProps = {
  placeholder: null,
  icon: 'caf-ic_search',
  name: '_search',
  disabled: false,
  className: '',
};

export default SearchInput;
