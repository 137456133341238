import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CustomCollapse from '../CustomCollapse';
import PolicyRow from '../PolicyRow';

const I18N_BASE_PATH =
  'pages.private.accessPolicies.components.policiesSelect.actions.components.trustPanels.routinesPanel';
const RULE_NAME = 'routines';

const RoutinesPanel = ({ disabled, formRef }) => {
  const { t: translate } = useTranslation();
  return (
    <CustomCollapse
      ruleName={RULE_NAME}
      disabled={disabled}
      className="no-border-bottom"
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="ic_clock"
    >
      <PolicyRow
        formRef={formRef}
        disabled={disabled}
        title={translate(`${I18N_BASE_PATH}.policyRows.titleRoutines`)}
        icon="ic_clock"
        ruleName={RULE_NAME}
        availability={[true, true, true, true]}
      />
    </CustomCollapse>
  );
};

RoutinesPanel.propTypes = {
  disabled: PropTypes.bool,
  formRef: PropTypes.func,
};

RoutinesPanel.defaultProps = {
  disabled: false,
  formRef: null,
};

export default RoutinesPanel;
