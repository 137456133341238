/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Switch } from 'antd';

const HeaderSwitch = ({ name, isActive, setIsActive, title, icon, disabled }) => {
  const { t: translate } = useTranslation();

  return (
    <div className="text-bold flex align-center">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Form.Item name={name} className="no-mrg no-pdd">
          <Switch
            key="metrics-switch"
            defaultChecked
            checked={isActive}
            onClick={setIsActive}
            disabled={disabled}
          />
        </Form.Item>
      </div>
      <i className={`caf-${icon} font-size-20 mrg-horizon-10`} />
      {translate(title)}
      {/* TODO: colocar aqui o contador de quantidade de coisas selecionadas */}
    </div>
  );
};

export default HeaderSwitch;
