/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { useTranslation } from 'react-i18next';

import CustomCollapse from '../CustomCollapse';
import SpecialPolicyRow from '../SpecialPolicyRow';

const I18N_BASE_PATH =
  'pages.private.accessPolicies.components.policiesSelect.actions.components.trustPanels.specialRulesPanel';

const SpecialPanel = ({ disabled, hideProfileRelatedRows }) => {
  const { t: translate } = useTranslation();

  return (
    <CustomCollapse
      ruleName="special-rules"
      disabled={disabled}
      className="no-border-top"
      title={translate(`${I18N_BASE_PATH}.title`)}
      icon="ic_code"
    >
      <div className="special-rules">
        {!hideProfileRelatedRows && (
          <>
            <SpecialPolicyRow
              disabled={disabled}
              title={translate(`${I18N_BASE_PATH}.policyRows.titleBlockProfile`)}
              icon="ic_close_small"
              ruleName="block-profile-details"
              policyName="special-rules"
            />
          </>
        )}

        <SpecialPolicyRow
          disabled={disabled}
          title={translate(`${I18N_BASE_PATH}.policyRows.titleBlockExecutions`)}
          icon="ic_close_small"
          ruleName="block-executions-details"
          policyName="special-rules"
        />

        {!hideProfileRelatedRows && (
          <>
            <SpecialPolicyRow
              disabled={disabled}
              title={translate(`${I18N_BASE_PATH}.policyRows.titleConsultation`)}
              icon="ic_checkmark_small"
              ruleName="allow-manual-query"
              policyName="special-rules"
            />

            <SpecialPolicyRow
              disabled={disabled}
              title={translate(`${I18N_BASE_PATH}.policyRows.titleBlockPJ`)}
              icon="ic_close_small"
              ruleName="block-companies-confidential-info"
              policyName="special-rules"
            />

            <SpecialPolicyRow
              disabled={disabled}
              title={translate(`${I18N_BASE_PATH}.policyRows.titleBlockPF`)}
              icon="ic_close_small"
              ruleName="block-people-confidential-info"
              policyName="special-rules"
            />

            <SpecialPolicyRow
              disabled={disabled}
              title={translate(`${I18N_BASE_PATH}.policyRows.titleHideView`)}
              icon="ic_close_small"
              ruleName="block-profiles-by-tag"
              policyName="special-rules"
              allowedTenants={[
                '1083c7ad-4b04-4d3b-b344-65d9bd3336c4',
                'fb3d6bd4-7892-436a-b9b1-9f284389995e',
                'e8a13640-40be-4ac3-91a4-bbbc488dff61',
              ]}
            />
          </>
        )}
      </div>
    </CustomCollapse>
  );
};

export default SpecialPanel;
