import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';

import useChangePassword from './ChangePasswordModal';

const ActionsList = ({ user }) => {
  const { t: translate } = useTranslation();
  const {
    openModal: openChangePasswordModal,
    ChangePasswordModalContent,
  } = useChangePassword({ user });

  return (
    <>
      {ChangePasswordModalContent}
      <Dropdown
        overlay={
          <Menu className="pdd-vertical-5">
            <Menu.Item
              onClick={openChangePasswordModal}
              key="updatePassword"
              className="text-dark"
            >
              <i className="caf-ic_key mrg-right-10" />
              <span className="text-semibold">
                {translate(
                  'pages.private.users.components.usersActions.actionsList.passwordRecovery'
                )}
              </span>
            </Menu.Item>

            {/* <Menu.Item key="updateMail" className="text-dark">
              <i className="caf-ic_mail mrg-right-10" />
              <span className="text-semibold">{translate(
                  'pages.private.users.components.usersActions.actionsList.changeMail'
              )}</span>
            </Menu.Item> */}
            {/* {hasRequiredPermissions(loggedUser, ['users:delete'], []) && (
              <Menu.Divider className="mrg-horizon-15 mrg-vertical-5" />
            )}
            {hasRequiredPermissions(loggedUser, ['users:delete'], []) && (
              <Menu.Item key="deleteUser" className="text-danger" onClick={openModal}>
                <i className="caf-ic_user_inactive mrg-right-10" />
                <span className="text-semibold">
                  {translate(
                    'pages.private.users.components.usersActions.actionsList.disableUser'
                  )}
                </span>
              </Menu.Item>
            )} */}
          </Menu>
        }
        trigger={['click']}
        placement="bottomRight"
      >
        <div className="btn-more-icon btn-more-icon-hover">
          <i className="caf-ic_more-vertical" />
        </div>
      </Dropdown>
    </>
  );
};

ActionsList.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
  }),
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

ActionsList.defaultProps = {
  user: {},
};

export default ActionsList;
