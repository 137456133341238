import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Modal, Form, Input, message } from 'antd';
import { PageTitle, Button } from '@combateafraude/react';

import { useGroup } from 'hooks/group';

import api from 'services/api';

const UpdateGroup = () => {
  const [form] = Form.useForm();
  const { t: translate } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const { group, loading, refreshGroup } = useGroup();

  const [modalVisible, setModalVisible] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const closeModal = useCallback(
    ({ resetFields = true }) => {
      setModalVisible(false);

      setTimeout(() => {
        if (resetFields) form.resetFields();
        setDisabledSubmit(true);
      }, 500);
    },
    [form]
  );

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleFormChange = useCallback(
    (values) => {
      if (id && values?.name?.trim() === group?.name?.trim()) {
        setDisabledSubmit(true);
      } else {
        setDisabledSubmit(false);
      }
    },
    [group, id]
  );

  const onUpdateGroup = useCallback(
    async (payload) => {
      try {
        const { name } = payload;

        if (id) {
          await api.put(`${process.env.REACT_APP_BASE_URL_AUTH_API}/users-groups/${id}`, {
            name,
          });
          refreshGroup();
          message.success(
            translate(
              'pages.private.groups.components.groupActions.updateGroup.onUpdateGroup.messageSuccessPut'
            )
          );
        } else {
          const { data: response } = await api.post(
            `${process.env.REACT_APP_BASE_URL_AUTH_API}/users-groups`,
            {
              name,
            }
          );
          history.push(`/users-permissions/groups/${response?.data?.id}`);

          message.success(
            translate(
              'pages.private.groups.components.groupActions.updateGroup.onUpdateGroup.messageSuccessPost'
            )
          );
        }

        closeModal({ resetFields: true });
      } catch (err) {
        message.error(
          translate(
            'pages.private.groups.components.groupActions.updateGroup.onUpdateGroup.messageError'
          )
        );
      }
    },
    [id, closeModal, refreshGroup, history, translate]
  );

  const UpdateGroupModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={550}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            title={translate(
              'pages.private.groups.components.groupActions.updateGroup.pageTitle.title'
            )}
            subtitle={translate(
              'pages.private.groups.components.groupActions.updateGroup.pageTitle.subtitle'
            )}
          />

          <Form
            form={form}
            layout="vertical"
            initialValues={{ name: group?.name }}
            onValuesChange={handleFormChange}
            validateTrigger="finish"
            className="mrg-top-30"
            onFinish={onUpdateGroup}
          >
            <Form.Item
              name="name"
              label={translate(
                'pages.private.groups.components.groupActions.updateGroup.name'
              )}
              className="custom-label"
              rules={[{ required: true }]}
            >
              <Input
                placeholder={translate(
                  'pages.private.groups.components.groupActions.updateGroup.name'
                )}
                className="custom-input pdd-vertical-10 no-pdd-horizon"
              />
            </Form.Item>

            <div className="flex end-center mrg-top-40">
              <Button
                type="default"
                onClick={closeModal}
                className="btn-cancel"
                disabled={loading}
              >
                {translate(
                  'pages.private.groups.components.groupActions.updateGroup.buttons.cancel'
                )}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={disabledSubmit || loading}
              >
                {loading
                  ? translate(
                      'pages.private.groups.components.groupActions.updateGroup.buttons.loading'
                    )
                  : translate(
                      'pages.private.groups.components.groupActions.updateGroup.buttons.okText'
                    )}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      form,
      group,
      handleFormChange,
      onUpdateGroup,
      loading,
      disabledSubmit,
      translate,
    ]
  );

  return { openModal, closeModal, UpdateGroupModal };
};

export default UpdateGroup;
