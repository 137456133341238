import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/auth';

import { hasRequiredPermissions } from './permissionFunctions';

const PermissionWrapper = ({
  children,
  or,
  requiredPermissions,
  requiredResources,
  forbiddenContent,
}) => {
  const { loggedUser } = useAuth();

  const content = useMemo(
    () =>
      hasRequiredPermissions(loggedUser, requiredPermissions, requiredResources || [], or)
        ? children
        : forbiddenContent,
    [requiredPermissions, loggedUser] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return content;
};

PermissionWrapper.propTypes = {
  requiredPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  requiredResources: PropTypes.arrayOf(PropTypes.string),
  forbiddenContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  or: PropTypes.bool,
};

PermissionWrapper.defaultProps = {
  requiredResources: [],
  forbiddenContent: <></>,
  or: false,
};

export default memo(PermissionWrapper);
