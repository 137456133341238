import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import PermissionWrapper from 'routes/PermissionWrapper';

import Wrapper from 'pages/private/wrapper';

import './styles.less';

const CustomerWrapper = ({ children }) => {
  const { t: translate } = useTranslation();
  useEffect(() => {}, []);
  return (
    <Wrapper id="customer-wrapper-component">
      <div className="tabs">
        <PermissionWrapper requiredPermissions={['users:read']}>
          <NavLink to="/users-permissions/users" activeClassName="active">
            <i className="caf-ic_person" />
            {translate('pages.private.users.components.wrapper.users')}
          </NavLink>
        </PermissionWrapper>
        <PermissionWrapper requiredPermissions={['groups:read']}>
          <NavLink to="/users-permissions/groups" activeClassName="active">
            <i className="caf-ic_users" />
            {translate('pages.private.users.components.wrapper.groups')}
          </NavLink>
        </PermissionWrapper>
        <PermissionWrapper requiredPermissions={['permissions:read']}>
          <NavLink to="/users-permissions/access-policies" activeClassName="active">
            <i className="caf-ic_lock_valid" />
            {translate('pages.private.users.components.wrapper.policies')}
          </NavLink>
        </PermissionWrapper>
      </div>
      {children}
    </Wrapper>
  );
};

export default CustomerWrapper;
