import React from 'react';
import PropTypes from 'prop-types';

import ImageCard from 'components/Image/Card';
import { toMaskedCpf } from 'utils/formatters';

import './styles.less';

const Card = ({ data, refreshList }) => (
  <div className="private-faceset-card">
    {data?.similarity && (
      <div className="similarity">{Number(data?.similarity).toFixed(2)}%</div>
    )}
    <ImageCard
      image={data?.image}
      title={data?.cpf && toMaskedCpf(data?.cpf)}
      subtitle={data?.description}
      refreshList={refreshList}
      _id={data?._id}
    />
  </div>
);
Card.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    cpf: PropTypes.string,
    description: PropTypes.string,
    similarity: PropTypes.number,
    _id: PropTypes.string,
  }).isRequired,
  refreshList: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
};

export default Card;
