export const scrollToTop = () => {
  document.getElementById('main-content').scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const scrollToBottom = () => {
  window.scrollTo(0, document.body.scrollHeight);
};

export const scrollToElement = (id) => {
  const elmnt = document.getElementById(id) || document.getElementById('main-content');
  elmnt.scrollIntoView({ top: 0, behavior: 'smooth' });
};
