import React from 'react';
import PropTypes from 'prop-types';
import { Card as CardAntd } from 'antd';

const Card = ({ className, children, ...rest }) => (
  <CardAntd {...rest} className={`gx-card-widget ${className}`}>
    {children}
  </CardAntd>
);

Card.propTypes = {
  className: PropTypes.string,
};

Card.defaultProps = {
  className: '',
};

export default Card;
