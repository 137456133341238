import React, { createContext, useCallback, useContext, useState } from 'react';

import { appendStyle, changeFavicon } from 'utils/handleThemeChange';

import ABCLogo from 'assets/images/abc-logo.svg';
import ManagementLogo from 'assets/images/products/management.svg';

const ThemeContext = createContext({});

const ThemeProvider = ({ children }) => {
  const [loadingTheme, setLoadingTheme] = useState(false);
  const [domain, setDomain] = useState('caf');
  const [logo, setLogo] = useState(ManagementLogo);

  const changeTheme = useCallback((domainName) => {
    if (domainName && domainName !== 'caf') {
      setLoadingTheme(true);
    }

    setDomain(domainName || 'caf');

    if (domainName === 'bancoabcbrasil') {
      appendStyle('abc-custom');
      changeFavicon('/icon-abc.svg');
      setLogo(ABCLogo);
    }
    setTimeout(() => {
      setLoadingTheme(false);
    }, 500);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        domain,
        logo,
        loadingTheme,
        changeTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

function useTheme() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used inside ThemeContext');
  }

  return context;
}

export { ThemeProvider, useTheme };
