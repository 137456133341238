import React, { useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select, message } from 'antd';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';
import { Button, Tag, PageTitle } from '@combateafraude/react';
import SearchInput from 'components/Form/SearchInput';
import _ from 'lodash';
import { useAuth } from 'hooks/auth';
import { useGroup } from 'hooks/group';
import { useFetch } from 'services/hooks';
import useDynamoTable from 'components/List/hooks/useDynamoTable';
import PermissionWrapper from 'routes/PermissionWrapper';
import useUpdateGroup from 'pages/private/Groups/components/GroupActions/UpdateGroup';
import useCreateUsers from '../UsersActions/CreateUsers';

import ActionsList from '../UsersActions/ActionsList';

import useChangeUserStatus from './components/ChangeUserStatus';
import SwitchRender from './components/SwitchRenderer';

import Wrapper from '../wrapper';
// import { Container } from `./styles`;
const { Option } = Select;

const I18N = `pages.private.users.components.usersList.index`;

const UsersList = () => {
  const { t: translate } = useTranslation();
  const { loggedUser } = useAuth();
  const refreshListRef = useRef(() => {});
  const { post } = useFetch();
  const { listGroups, loading } = useGroup();
  const [query] = useQueryParams({
    search: StringParam,
    active: withDefault(StringParam, `true`),
  });

  const { openModal: openModalGroup, UpdateGroupModal } = useUpdateGroup();
  const { openModal: openChangeStatusModal, ChangeUserStatusModal } = useChangeUserStatus(
    {
      refreshListRef,
    }
  );

  const { openModal, CreateUsersModal } = useCreateUsers({
    refreshListRef,
    openModalGroup,
  });

  const changeUserGroup = useCallback(
    async (userId, groupId) => {
      try {
        await post({
          url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users-groups/${
            groupId || 'no_access'
          }/users`,
          payload: { usersIds: [userId] },
        });
        message.success(translate(`${I18N}.changeUserGroup.messageSuccess`));
      } catch {
        message.error(translate(`${I18N}.changeUserGroup.messageError`));
      }
    },
    [post, translate]
  );

  const findGroupById = useCallback(
    (id) => {
      const group = listGroups.find((g) => g.id === id);
      return group?.name || 'Sem acesso';
    },
    [listGroups]
  );

  const columns = [
    {
      key: 'name',
      title: <span className="column-title">{translate(`${I18N}.columns.name`)}</span>,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (__, record) => (
        <div className="flex align-center text-dark">
          {record?.name}
          {record?.id === loggedUser?.id && (
            <Tag className="mrg-left-10 tag-you">{translate(`${I18N}.you`)}</Tag>
          )}
        </div>
      ),
    },
    {
      key: 'email',
      title: <span className="column-title">{translate(`${I18N}.columns.email`)}</span>,
      dataIndex: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      key: 'group',
      title: <span className="column-title">{translate(`${I18N}.columns.group`)}</span>,
      dataIndex: 'group',
      render: (__, record) =>
        !loading && (
          <PermissionWrapper
            requiredPermissions={['users:update']}
            forbiddenContent={
              <span className="text-dark text-bold no-pdd">
                {findGroupById(record?.usersGroupId)}
              </span>
            }
          >
            <Select
              showSearch
              optionFilterProp="children"
              bordered={false}
              style={{ width: '200px' }}
              className="text-dark text-bold no-pdd"
              defaultValue={record?.usersGroupId || ''}
              onSelect={(e) => changeUserGroup(record?.id, e)}
            >
              {listGroups?.map((group) => (
                <Option value={group?.id}>{group?.name}</Option>
              ))}
            </Select>
          </PermissionWrapper>
        ),
    },
    {
      key: 'deletedAt',
      title: <span className="column-title">{translate(`${I18N}.columns.status`)}</span>,
      render: (__, record) => (
        <SwitchRender data={record} openModal={openChangeStatusModal} />
      ),
    },
    {
      key: 'actions',
      render: (__, record) => (
        <ActionsList user={record} refreshListRef={refreshListRef} />
      ),
      width: 60,
    },
  ];

  const { tableContent, refreshList, setFilterData } = useDynamoTable({
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users/all`,
      config: {
        params: {},
      },
    },
    queryParams: {
      search: StringParam,
      active: withDefault(StringParam, 'true'),
    },
    columns,
    rowKey: 'id',
    customLoading: loading,
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        active: values.active && values.active !== 'all' ? values.active : undefined,
        search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );

  return (
    <Wrapper>
      {CreateUsersModal}
      {UpdateGroupModal}
      {ChangeUserStatusModal}
      <div className="flex align-center gx-w-100 space-between">
        <PageTitle
          title={translate(`${I18N}.pageTitle.title`)}
          subtitle={translate(`${I18N}.pageTitle.subtitle`)}
        />
        <div className="flex row mrg-top-15 mrg-btm-30 align-center">
          <Form
            layout="horizontal"
            className="flex align-center"
            onValuesChange={handleFormChange}
            initialValues={{ _search: query?.search, active: query?.active }}
          >
            <Form.Item name="active" className="no-mrg-btm">
              <Select
                bordered={false}
                style={{ width: 100, fontWeight: 500, marginRight: 20 }}
                className="text-dark"
              >
                <Option value="all">{translate(`${I18N}.activeInput.all`)}</Option>
                <Option value="true">{translate(`${I18N}.activeInput.active`)}</Option>
                <Option value="false">{translate(`${I18N}.activeInput.inactive`)}</Option>
              </Select>
            </Form.Item>
            <SearchInput placeholder={translate(`${I18N}.searchInput`)} />
          </Form>
          <PermissionWrapper requiredPermissions={[`users:create`]}>
            <div className="mrg-left-15">
              <Button type="primary" className="flex center" onClick={openModal}>
                <i className="caf-ic_plus font-size-15 mrg-right-5" />
                <span>{translate(`${I18N}.newUser`)}</span>
              </Button>
            </div>
          </PermissionWrapper>
        </div>
      </div>
      <div className="gx-w-100 mrg-top-10">{tableContent}</div>
    </Wrapper>
  );
};

export default UsersList;
