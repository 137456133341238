import React from 'react';
import PropTypes from 'prop-types';

import ImageCard from 'components/Image/Card';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import { toMaskedCpf } from 'utils/formatters';

const Card = ({ data }) => {
  const { i18nFormatDate } = useI18nFormatters();

  return (
    <ImageCard
      image={data?.image}
      title={toMaskedCpf(data?.peopleId || '00000000000')}
      subtitle={i18nFormatDate(data?.createdAt, 'default')}
    />
  );
};

Card.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    peopleId: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
};

export default Card;
